import { useEffect, useState, useMemo } from 'react'
import { useRouter } from 'next/router'
import { Header } from '@/components/layouts/Header'
import { Footer } from '@/components/layouts/Footer'
import { useAuth } from '../../../context/AuthContext'
import TagManager from 'react-gtm-module'
import Cookies from '@/components/common/Cookies'
import GoogleAnalytics from '@/components/googleAnalytics'

const hideContentRouteList = [
  '/dashboard',
  '/audios',
  '/login',
  '/register',
  '/forgot-password',
  '/reset-password',
  '/profile',
  '/settings',
  '/integrations',
]

export const ContentWrapper = ({ Component, ...pageProps }) => {
  const router = useRouter()
  const { currentUser } = useAuth()
  const [hideContent, setHideContent] = useState(false)

  // Initialize Tag Manager only once
  useEffect(() => {
    TagManager.initialize({ gtmId: 'GTM-WBL856P' })
  }, [])

  const shouldHideContent = useMemo(() => {
    const pathname = router.pathname
    if (hideContentRouteList.includes(pathname)) return true

    const pathSegments = pathname.split('/').filter(Boolean)
    if (pathSegments.length > 1) {
      const basePath = `/${pathSegments[0]}`
      return hideContentRouteList.includes(basePath)
    }

    return false
  }, [router.pathname])

  useEffect(() => {
    setHideContent(shouldHideContent)
  }, [shouldHideContent])

  return (
    <>
      {process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS ? (
        <GoogleAnalytics ga_id={process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS} />
      ) : null}
      {!hideContent && <Header currentUser={currentUser} />}
      <Component {...pageProps} />
      {!hideContent && <Cookies />}
      {!hideContent && <Footer />}
    </>
  )
}
