import React, { useContext, useEffect, useState, useRef } from 'react';
import { useAuth } from './AuthContext';
import { useNotification } from './NotificationContext';
import { doc, updateDoc, onSnapshot } from 'firebase/firestore';
import { db } from '../firebase';
import { TimeHelper } from '../src/utils/helpers/timeHelper';
import { NumberHelper } from '../src/utils/helpers/numberHelper';

const CreditContext = React.createContext('');

export function useCredit() {
  return useContext(CreditContext);
}

export function CreditProvider({ children }) {
  const { currentUser } = useAuth();
  const { addNotification } = useNotification();
  const userDocRef = useRef(null);
  const [audioCredits, setAudioCredits] = useState(null);
  const [contentCredits, setContentCredits] = useState(null);

  const updateCreditBalance = (usageType, usageAmount) => {
    if (usageType === 'audio') {
      updateAudioCredits(usageAmount);
    } else {
      updateContentCredits(usageAmount);
    }
  };

  const updateAudioCredits = async (usageAmount) => {
    const newCredit = audioCredits - TimeHelper.timeToMinutes(usageAmount);
    const isSufficientCredit = audioCredits >= TimeHelper.timeToMinutes(usageAmount);
    const isNumber = NumberHelper.isNumber(newCredit);

    if (isSufficientCredit && isNumber) {
      try {
        await updateDoc(userDocRef.current, {
          'credits.audio': newCredit,
        });
        setAudioCredits(newCredit);
      } catch (error) {
        addNotification({
          id: Date.now(),
          message: 'Failed to update audio credits. Please try again.',
          type: 'error',
        });
      }
    } else {
      addNotification({
        id: Date.now(),
        message: 'Insufficient audio credits to use this feature. Please upgrade your plan.',
        type: 'error',
      });
    }
  };

  const updateContentCredits = async (usageAmount) => {
    const newCredit = contentCredits - usageAmount;
    const isSufficientCredit = contentCredits >= usageAmount;
    const isNumber = NumberHelper.isNumber(newCredit);

    if (isSufficientCredit && isNumber) {
      try {
        await updateDoc(userDocRef.current, {
          'credits.content': newCredit,
        });
        setContentCredits(newCredit);
      } catch (error) {
        addNotification({
          id: Date.now(),
          message: 'Failed to update content credits. Please try again.',
          type: 'error',
        });
      }
    } else {
      addNotification({
        id: Date.now(),
        message: 'Insufficient content credits to use this feature. Please upgrade your plan.',
        type: 'error',
      });
    }
  };

  const getUserAudioCredit = () => {
    if (currentUser) {
      setAudioCredits(currentUser.credits.audio);
    }
    return currentUser?.credits.audio;
  };

  const getUserContentCredit = () => {
    if (currentUser) {
      setContentCredits(currentUser.credits.content);
    }
    return currentUser?.credits.content;
  };

  const listenToUserDocs = () => {
    return onSnapshot(userDocRef.current, (docSnap) => {
      if (docSnap.exists()) {
        const data = docSnap.data();
        setAudioCredits(data.credits.audio);
        setContentCredits(data.credits.content);
      }
    });
  };

  useEffect(() => {
    let unsubscribe;

    if (currentUser && currentUser.uid) {
      userDocRef.current = doc(db, 'users', currentUser.uid);
      getUserAudioCredit();
      getUserContentCredit();
      unsubscribe = listenToUserDocs();
    }

    return () => {
      if (unsubscribe) unsubscribe();
    };
  }, [currentUser]);

  const value = {
    audioCredits,
    contentCredits,
    updateCreditBalance,
    getUserAudioCredit,
    getUserContentCredit,
  };

  return (
    <CreditContext.Provider value={value}>
      {children}
    </CreditContext.Provider>
  );
}
