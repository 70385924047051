export const CreditConstants = [
  {
    name: 'starter',
    contentCredit: 30,
    audioCredit: 120,
  },
  {
    name: 'basic',
    contentCredit: '200',
    audioCredit: 300,
  },
  {
    name: 'creator',
    contentCredit: 'unlimited',
    audioCredit: 1200,
  },
  {
    name: 'professional',
    contentCredit: 'unlimited',
    audioCredit: 'unlimited',
  },
]
