function numberWithCommas(x) {
    return x.toLocaleString()
  }
  
  function isNumber(x) {
    return !isNaN(x)
  }
  
  function calculatePercentage(part, whole) {
    return Math.ceil((part / whole) * 100);
  }

  // round up to the nearest multiple 
  function roundToCeiling(number, nearest = 1) {
    return Math.ceil(number / nearest) * nearest;
  } 
  
  export const NumberHelper = {
    isNumber,
    roundToCeiling,
    numberWithCommas,
    calculatePercentage,
  }