import React, { useContext, useState, useEffect, useRef, useMemo, useCallback } from 'react';
import { auth, db } from '../firebase';
import {
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  onAuthStateChanged,
  signOut,
  signInWithPopup,
  GoogleAuthProvider,
  updateEmail,
  updateProfile,
  sendPasswordResetEmail,
  sendEmailVerification,
  fetchSignInMethodsForEmail,
  deleteUser,
} from 'firebase/auth';
import { collection, onSnapshot } from 'firebase/firestore';
import { doc, getDoc, setDoc, updateDoc, deleteDoc } from 'firebase/firestore';
import { useNotification } from './NotificationContext';
import { useProduct } from './ProductContext';
import { addUserSpecificDocs, createUserStorage } from '@/utils/firebaseUtils';

const AuthContext = React.createContext('');

export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider({ children }) {
  const userRef = useRef(null);
  const userInfo = useRef(null);
  const userPaymentCollectionRef = useRef(null);
  const userSessionCollectionRef = useRef(null);
  const userSubscriptionCollectionRef = useRef(null);
  const userIntegrationsCollectionRef = useRef(null);

  const [loading, setLoading] = useState(true);
  const [isNewUser, setIsNewUser] = useState(false);
  const [payment, setPayment] = useState(null);
  const [session, setSession] = useState(null);
  const [integrations, setIntegrations] = useState(null);
  const [currentUser, setCurrentUser] = useState(null);
  const [subscription, setSubscription] = useState(null);

  const { addNotification } = useNotification();
  const { handleCheckoutSession, getProductByRole, products } = useProduct();

  const signUp = useCallback(async (email, password, userInfo) => {
    try {
      createUserWithEmailAndPassword(auth, email, password).then((userCredential) => {
        const user = userCredential.user;

        sendEmailVerification(user);
        createUser(user, userInfo.first_name, userInfo.last_name);

        addNotification({
          id: Date.now(),
          message: 'Please check your email to verify your account.',
          type: 'info',
        });

        return user;
      });
    } catch (error) {
      addNotification({
        id: Date.now(),
        message: error.message,
        type: 'error',
      });
    }
  }, []);

  const login = useCallback((email, password) => {
    return signInWithEmailAndPassword(auth, email, password);
  }, []);

  const logout = useCallback(() => {
    return signOut(auth);
  }, []);

  const resetPassword = useCallback((email) => {
    return sendPasswordResetEmail(auth, email);
  }, []);

  const updateUserEmail = useCallback((email) => {
    updateEmail(auth.currentUser, email).then(() => {
      updateDoc(userRef.current, {
        email: email,
      }).then(() => {
        getUserInfo(currentUser.uid);
      });
    });
  }, [currentUser]);

  const googleLogin = useCallback(() => {
    const provider = new GoogleAuthProvider();
    try {
      signInWithPopup(auth, provider).then(async (result) => {
        const user = result.user

        const newUser = result._tokenResponse.isNewUser
        setIsNewUser(newUser)

        if (newUser) {
          createUser(user, user.displayName.split(' ')[0], user.displayName.split(' ')[1]);
        }
      });
    } catch (error) {
      addNotification({
        id: Date.now(),
        message: error.message,
        type: 'error',
      });
    }
  }, []);

  const removeLinkedinIntegration = useCallback(async (userID) => {
    try {
      const deauthEndpoint = process.env.NEXT_PUBLIC_LINKEDIN_DEAUTHENTICATE;

      const response = await fetch(deauthEndpoint, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ userId: userID }),
      });

      if (!response.ok) {
        throw new Error(`Error: ${response.statusText}`);
      }

      addNotification({
        id: Date.now(),
        message: 'LinkedIn integration removed successfully.',
        type: 'success',
      });
    } catch (error) {
      addNotification({
        id: Date.now(),
        message: 'Failed to remove LinkedIn integration.',
        type: 'error',
      });
    }
  }, []);

  const getUserInfo = useCallback(async (uid) => {
    const docRef = doc(db, 'users', uid);

    try {
      const docSnapshot = await getDoc(docRef);
      if (docSnapshot.exists()) {
        userInfo.current = docSnapshot.data();
        return userInfo.current;
      } else {
        addNotification({
          id: Date.now(),
          message: 'User does not exist.',
          type: 'error',
        });
      }
    } catch (error) {
      addNotification({
        id: Date.now(),
        message: error.message,
        type: 'error',
      });
    }
  }, [addNotification]);

  const createUser = useCallback(
    async (user, first_name, last_name, plan = 'starter') => {
      if (!user) return;

      const buildUserData = () => {
        const productPlanInfo = getProductByRole(plan);
        const email = user.providerData[0]?.email || '';
        const photoURL = user.photoURL || '';
        const planName = productPlanInfo?.name || plan;

        return {
          email,
          uid: user.uid,
          displayName: `${first_name} ${last_name}`,
          firstName: first_name,
          lastName: last_name,
          photoURL,
          emailVerified: user.emailVerified,
          created: user.metadata.createdAt,
          credits: {
            content: 0,
            audio: 0,
          },
          productPlan: planName,
        };
      };

      try {
        if (user && user.uid) {
          const docRef = doc(db, 'users', user.uid);

          const userData = buildUserData();
          await setDoc(docRef, userData, { merge: true });

          await addUserSpecificDocs(user.uid);
          createUserStorage(user.uid);

          setCurrentUser(userData);

          await handleCheckoutSession(user.uid);
        }
      } catch (error) {
        addNotification({
          id: Date.now(),
          message: error.message,
          type: 'error',
        });
      }
    },
    [getProductByRole, handleCheckoutSession, addNotification]
  );

  const checkUserDocExists = useCallback(async (uid) => {
    const docRef = doc(db, 'users', uid);

    try {
      const docSnapshot = await getDoc(docRef);
      return docSnapshot.exists();
    } catch (error) {
      return false;
    }
  }, []);

  const deleteUserFromAuth = useCallback(async () => {
    try {
      await deleteDoc(userRef.current);
      await deleteUser(auth.currentUser);

      setCurrentUser(null);
      logout();

      addNotification({
        title: 'Profile deleted',
        message: 'Your profile has been deleted successfully.',
        type: 'success',
      });
    } catch (error) {
      addNotification({
        id: Date.now(),
        message: error.message,
        type: 'error',
      });
    }
  }, [addNotification, logout]);

  const updateUserProfile = useCallback(async (data) => {
    updateDoc(userRef.current, data).then(() => {
      getUserInfo(currentUser.uid);
    });

    updateProfile(auth.currentUser, {
      displayName: data.displayName,
      photoURL: data.photoURL,
    });
  }, [currentUser, getUserInfo]);

  const checkEmailExists = useCallback(async (email) => {
    try {
      const signInMethods = await fetchSignInMethodsForEmail(auth, email);
      return signInMethods.length > 0;
    } catch (error) {
      addNotification({
        id: Date.now(),
        message: error.message,
        type: 'error',
      });
    }
  }, [addNotification]);

  const listenToCollectionChanges = useCallback(async (collectionRef, updateFunction) => {
    try {
      const unsubscribe = onSnapshot(
        collectionRef,
        (snapshot) => {
          snapshot.docChanges().forEach((change) => {
            if (change.type === 'added' || change.type === 'modified') {
              updateFunction(change.doc.data());
            } else if (change.type === 'removed') {
              updateFunction(null);
            }
          });
        },
        (error) => {
          addNotification({
            id: Date.now(),
            message: `Error fetching data: ${error.message}`,
            type: 'error',
          });
        }
      );
      return unsubscribe;
    } catch (error) {
      addNotification({
        id: Date.now(),
        message: `Error setting listener: ${error.message}`,
        type: 'error',
      });
      return () => {};
    }
  }, [addNotification]);

  const deleteSecondaryRecords = useCallback(async () => {
    if (!currentUser || !currentUser.uid) {
      console.error('User not authenticated');
      return false;
    }

    try {
      const response = await fetch(process.env.NEXT_PUBLIC_DELETE_SECONDARY_RECORDS, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ user_id: currentUser.uid }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        console.error('Failed to delete secondary records:', errorData.error || 'Unknown error');
        return false;
      }

      const data = await response.json();

      if (response.status === 200) {
        console.log('Secondary records deleted successfully');
        return true;
      } else if (response.status === 204) {
        console.log('No secondary records found to delete');
        return true;
      }

      return false;
    } catch (error) {
      console.error('Error deleting secondary records:', error.message || 'Unknown error');
      return false;
    }
  }, [currentUser]);

  useEffect(() => {
    let paymentUnsubscribe = () => {};
    let subscriptionUnsubscribe = () => {};
    let sessionUnsubscribe = () => {};
    let integrationsUnsubscribe = () => {};

    const unsubscribeAuth = onAuthStateChanged(auth, (user) => {
      setLoading(true);

      if (user && user.uid && auth.currentUser) {
        getUserInfo(user.uid).then((res) => {
          setCurrentUser(res);

          userRef.current = doc(db, 'users', user.uid);

          userPaymentCollectionRef.current = collection(userRef.current, 'payments');
          userSubscriptionCollectionRef.current = collection(userRef.current, 'subscriptions');
          userSessionCollectionRef.current = collection(userRef.current, 'checkout_sessions');
          userIntegrationsCollectionRef.current = collection(userRef.current, 'integrations');

          listenToCollectionChanges(userPaymentCollectionRef.current, setPayment).then((unsubscribe) => {
            paymentUnsubscribe = unsubscribe;
          });
          listenToCollectionChanges(userSubscriptionCollectionRef.current, setSubscription).then((unsubscribe) => {
            subscriptionUnsubscribe = unsubscribe;
          });
          listenToCollectionChanges(userSessionCollectionRef.current, setSession).then((unsubscribe) => {
            sessionUnsubscribe = unsubscribe;
          });
          listenToCollectionChanges(userIntegrationsCollectionRef.current, setIntegrations).then((unsubscribe) => {
            integrationsUnsubscribe = unsubscribe;
          });
        });
      } else {
        setCurrentUser(null);
        logout();
      }
      setLoading(false);
    });

    return () => {
      unsubscribeAuth();
      paymentUnsubscribe();
      subscriptionUnsubscribe();
      sessionUnsubscribe();
      integrationsUnsubscribe();
    };
  }, [getUserInfo, listenToCollectionChanges, logout]);

  const value = useMemo(
    () => ({
      login,
      logout,
      signUp,
      payment,
      session,
      integrations,
      removeLinkedinIntegration,
      userInfo,
      isNewUser,
      deleteUserFromAuth,
      createUser,
      currentUser,
      googleLogin,
      getUserInfo,
      subscription,
      resetPassword,
      updateUserEmail,
      checkEmailExists,
      updateUserProfile,
      checkUserDocExists,
      deleteSecondaryRecords,
    }),
    [
      login,
      logout,
      signUp,
      payment,
      session,
      integrations,
      removeLinkedinIntegration,
      userInfo,
      isNewUser,
      deleteUserFromAuth,
      createUser,
      currentUser,
      googleLogin,
      getUserInfo,
      subscription,
      resetPassword,
      updateUserEmail,
      checkEmailExists,
      updateUserProfile,
      checkUserDocExists,
      deleteSecondaryRecords,
    ]
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}