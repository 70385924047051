import React, { useState } from 'react'
import { Button } from '../common/Button'
import { useRouter } from 'next/router'

const CookieToggle = ({ category, onUpdatePreference }) => {
  const [isEnabled, setIsEnabled] = useState(false)

  const toggleSwitch = () => {
    const newEnabledStatus = !isEnabled;
    setIsEnabled(newEnabledStatus);
    onUpdatePreference(category.name, newEnabledStatus);
  }

  return (
    <div className="mt-4 flex items-center justify-between pl-4 pr-8">
      <p className="font-medium">{category.name}</p>
      {category.isAlwaysActive ? ( // If the category is active, don't show the toggle
        <div className="text-xs font-bold text-indigo-500">Always Active</div>
      ) : (
        <button
          className={`flex h-5 w-10 items-center rounded-full duration-300 ease-in-out ${
            isEnabled ? 'bg-indigo-500' : 'bg-gray-300 p-1'
          }`}
          onClick={toggleSwitch}
        >
          <div
            className={`h-4 w-4 transform rounded-full bg-white shadow-md duration-300 ease-in-out ${
              isEnabled ? 'translate-x-5' : 'translate-x-0'
            }`}
          ></div>
        </button>
      )}
    </div>
  )
}

export default function ManageCookies({ content }) {
  const [userPreferences, setUserPreferences] = useState(
    content.categories.map((category) => ({
      name: category.name,
      active: category.active,
    }))
  );
  const router = useRouter()
  const onCookiePolicyRoute = () => {
    content.handleModalClose()
    router.push('/cookie-policy')
  }
  const handleTogglePreference = (categoryName, isEnabled) => {
    const updatedPreferences = userPreferences.map((preference) => {
      if (preference.name === categoryName) {
        return { ...preference, active: isEnabled };
      }
      return preference;
    });

    setUserPreferences(updatedPreferences);
  };

  const handleAcceptUserPreferences = () => {
    content.onAcceptUserPreferences(userPreferences);
  };

  return (
    <>
      {
        <div className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-2xl sm:pl-6">
          <div className="max-h-[40em] overflow-y-auto overflow-x-hidden">
            <div className="mb-4 mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
              <h2 className="text-xl font-bold">Manage Cookies</h2>
              <div className="mt-2">
                <p className="text-sm">
                  OpenSpeech uses cookies and similar technologies to collect
                  information about your use of our website. Some cookies are
                  essential to ensure our website functions properly and cannot
                  be turned off. Others are optional, allowing you the choice to
                  accept or decline them. Want to know more? Check out our full{' '}
                  <Button onClick={onCookiePolicyRoute} simpleLink={true}>
                    Cookie Policy
                  </Button>
                  .
                </p>
              </div>
              <Button
                onClick={() => content.onAcceptAllCookies()}
                variant="solid"
                color="indigo"
                className="sm:my-3"
              >
                Accept all
              </Button>
            </div>

            {content.categories.map((category, index) => (
              <div key={index}>
                <CookieToggle category={category} onUpdatePreference={handleTogglePreference} />
                <div className="mt-2 px-4">
                  <p className="text-sm">{category.description}</p>
                </div>
              </div>
            ))}
          </div>

          <hr className="my-4 border-t border-gray-100" />

          <div className="mt-5 justify-end sm:mt-4 sm:flex">
            <Button
              onClick={() => content.onRejectNonEssentialCookies()}
              variant="solid"
              color="grey"
              className="w-full sm:ml-3 sm:w-auto"
            >
              Reject all
            </Button>
            <Button
              onClick={handleAcceptUserPreferences}
              variant="solid"
              color="indigo"
              className="w-full sm:ml-3 sm:w-auto"
            >
              Save and close
            </Button>
          </div>
        </div>
      }
    </>
  )
}
