import React, { useEffect } from 'react';
import { Button } from '@/components/common/Button';
import { useContent } from '../../../context/ContentContext';
import { useAuth } from 'context/AuthContext';
import { useRouter } from 'next/router';
import { isTokenExpiring } from '@/utils/helpers/integrations/linkedInHelper';

export default function ShareContentToLinkedIn({ content, onClose }) {

    const { shareContentToLinkedIn } = useContent();
    const { integrations } = useAuth();
    const router = useRouter();

    const handleIntegrateClick = () => {
        router.push('/integrations');
    };

    // Check if LinkedIn is integrated and if the token is expiring or has expired
    const linkedInIntegrationNeeded = !integrations || isTokenExpiring(integrations.issued_at, integrations.expires_in);

    if (linkedInIntegrationNeeded) {
        // User hasn't integrated LinkedIn or token is expiring in less than 3 days
        return (
            <div className="p-4">
                <h2 className="text-xl font-bold mb-4">LinkedIn Integration Needed</h2>
                <p className="mb-4">
                    To share content directly to LinkedIn, please integrate your LinkedIn account with OpenSpeech.
                    It's a quick and easy process that will enable you to share your amazing content effortlessly!
                </p>
                <Button
                    className="bg-blue-500 hover:bg-blue-600 text-white"
                    onClick={handleIntegrateClick}>
                    Integrate LinkedIn Account
                </Button>
            </div>
        );
    }

    return (
        <div className="p-4">
            <h2 className="text-xl font-bold mb-4">Share Content to LinkedIn</h2>
            <p className="mb-4">Do you want to share this content on LinkedIn?</p>
            <div className="flex space-x-2">
                <Button
                    className="bg-green-500 hover:bg-green-600 text-white"
                    onClick={() => {
                        shareContentToLinkedIn(content);
                        onClose();
                    }}>
                    Yes
                </Button>
                <Button
                    className="bg-red-500 hover:bg-red-600 text-white"
                    onClick={onClose}>
                    No
                </Button>
            </div>
        </div>
    );
}