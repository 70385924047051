// TODO: Add notification when error
const isBrowser = typeof window !== 'undefined';

export function saveData(key, value) {
  try {
    localStorage.setItem(key, JSON.stringify(value));
  } catch (error) {
    console.error(error);
  }
}

export function getData(key) {
  if (isBrowser) {
    try {
      const value = localStorage.getItem(key);
      return value !== null ? JSON.parse(value) : null;
    } catch (error) {
      console.error('Error getting data from localStorage:', error);
      // TODO: Add notification when error
      return null;
    }
  }
  return null;
}

export function removeData(key) {
  try {
    localStorage.removeItem(key);
  } catch (error) {
    console.error(error);
  }
}

export function clearData() {
  try {
    localStorage.clear();
  } catch (error) {
    console.error(error);
  }
}

export const localStorageHelper = {
  saveData,
  getData,
  removeData,
  clearData,
};
