import { jsPDF } from 'jspdf'
import { VALID_FILE_TYPES } from '@/constants/ValidFileTypesConstants'

function downloadPDF(content) {
  if (!content) {
    return
  }

  const doc = new jsPDF()

  // Set the max-width of the lines (in units declared at inception of jsPDF instance)
  const maxWidth = 180;
  
  const lineHeight = 7; // Line height
  let currentHeight = 50; // Y position where we start to print the text

  const lines = doc.splitTextToSize(content, maxWidth);

  lines.forEach((line) => {
    doc.text(line, 10, currentHeight); // 10 is the X-axis position
    currentHeight += lineHeight;
  })

  doc.save('assessment.pdf')
}

function formatFileName(name) {
  if (!name) return

  const extension = name.split('.').pop()
  name = name.replace(`.${extension}`, '')
  name = name.replace(/[^a-zA-Z0-9 ]/g, '')
  return name
}

function formatDuration(duration) {
  const minutes = Math.floor(duration / 60)
  const seconds = Math.round(duration % 60)
  return minutes + ':' + (seconds < 10 ? '0' : '') + seconds
}

function isFileSizeValid(file, VALID_FILE_SIZE) {
  return file.size < VALID_FILE_SIZE
}

function isFileTypeValid(file) {
  const fileType = file.type
  return VALID_FILE_TYPES.findIndex((type) => type === fileType) >= 0
}

function getAudioDuration(file) {
  return new Promise((resolve, reject) => {
    if (!file || !(file instanceof Blob)) {
      reject(new Error("Invalid or missing file object"));
      return;
    }

    const audio = new Audio();
    audio.src = URL.createObjectURL(file);

    audio.addEventListener('loadedmetadata', () => {
      resolve(formatDuration(audio.duration));
    });

    audio.addEventListener('error', () => {
      reject(new Error('Failed to load audio file'));
    });
  });
}


export const fileHelper = {
  downloadPDF,
  formatFileName,
  formatDuration,
  isFileSizeValid,
  isFileTypeValid,
  getAudioDuration,
}
