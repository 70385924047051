import Link from 'next/link'
import clsx from 'clsx'

const baseStyles = {
  solid:
    'group inline-flex items-center justify-center rounded-lg py-2 px-4 text-sm font-semibold focus:outline-none focus-visible:outline-2 focus-visible:outline-offset-2',
  outline:
    'group inline-flex items-center justify-center rounded-lg py-2 px-4 text-sm outline-none',
  empty:
    'group inline-flex items-center justify-center rounded-lg py-2 px-4 text-sm outline-none',
}

const variantStyles = {
  solid: {
    indigo:
      'bg-indigo-600 text-white hover:bg-indigo-700 hover:text-indigo-100 active:bg-indigo-800 active:text-indigo-300 focus-visible:outline-indigo-900',
    white:
      'bg-white text-indigo-900 hover:bg-indigo-50 active:bg-indigo-200 active:text-indigo-600 focus-visible:outline-white',
    red: 'bg-red-600 text-white hover:bg-red-700 active:bg-red-800',
    green: 'bg-green-600 text-white hover:bg-green-700 active:bg-green-800',
    black: 'bg-black text-white hover:bg-gray-900 active:bg-gray-800',
    yellow: 'bg-yellow-600 text-white hover:bg-yellow-700 active:bg-yellow-800',
    gray: 'bg-gray-600 text-white hover:bg-gray-700 active:bg-gray-800',
  },
  outline: {
    indigo:
      'ring-indigo-200 text-indigo-700 hover:text-indigo-900 hover:ring-indigo-300 active:bg-indigo-100 active:text-indigo-600 focus-visible:outline-indigo-600 focus-visible:ring-indigo-300 ring-1 ring-inset',
    white:
      'ring-indigo-700 text-white hover:ring-indigo-500 active:ring-indigo-700 active:text-indigo-400 focus-visible:outline-white',
    red: 'ring-red-100 text-red-600 hover:text-red-800 hover:ring-red-300 focus-visible:ring-red-300 ring-1 ring-inset',
    green:
      'ring-green-100 text-green-600 hover:text-green-800 hover:ring-green-300 focus-visible:ring-green-300 ring-1 ring-inset',
    black:
      'ring-gray-200 text-gray-600 hover:text-gray-800 hover:ring-gray-300',
    yellow:
      'bg-yellow-100 ring-yellow-200 text-yellow-600 hover:text-yellow-800 hover:ring-yellow-300',
  },
  empty: {
    indigo: 'text-indigo-600',
    white:
      'text-white hover:text-indigo-50 active:bg-indigo-200 active:text-indigo-600 focus-visible:outline-white',
    red: 'text-red-600 hover:text-red-800',
    green: 'text-green-600 hover:text-green-800',
    black: 'text-gray-600 hover:text-gray-800',
    yellow: 'text-yellow-600 hover:text-yellow-800',
  },
  icon: {
    indigo: 'bg-indigo-600 hover:bg-indigo-900 rounded-md text-white p-1',
    white: 'bg-white hover:bg-indigo-50 rounded-md text-white p-1',
    red: 'bg-red-600 hover:bg-red-800 rounded-md text-white p-1',
    green: 'bg-green-400 hover:bg-green-500 rounded-md text-white p-1',
    black: 'bg-gray-600 hover:bg-gray-800 rounded-md text-white p-1',
    yellow: 'bg-yellow-400 hover:bg-yellow-500 rounded-md text-white p-1',
    blue: 'bg-blue-400 hover:bg-blue-500 rounded-md text-white p-1',
    gray: 'bg-gray-400 hover:bg-gray-500 rounded-md text-white p-1',
  },
}

export function Button({
  variant = 'solid',
  color = 'indigo',
  className,
  href,
  simpleLink = false,
  disabled,
  loading,
  tooltip,
  message = 'Loading...',
  ...props
}) {
  className = clsx(
    baseStyles[variant],
    variantStyles[variant][color],
    className,
    {
      'opacity-50 cursor-not-allowed': disabled,
    }
  )

  if (loading && message) {
    return (
      <button className={className} disabled>
        <div className="flex items-center justify-center space-x-2">
          <svg
            aria-hidden="true"
            className="h-6 w-6 animate-spin fill-indigo-600 text-gray-200"
            viewBox="0 0 100 101"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
              fill="currentColor"
            />
            <path
              d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
              fill="currentFill"
            />
          </svg>
          <span>{message}</span>
        </div>
      </button>
    )
  }

  const button = href ? (
    <Link
      href={href}
      className={simpleLink ? 'text-indigo-500 underline' : className}
      {...props}
    />
  ) : (
    <button
      className={simpleLink ? 'text-indigo-500 underline' : className}
      {...props}
    />
  )

  return tooltip ? (
    <div className="group relative">
      {button}
      <div className="absolute left-1/2 mt-2 w-max -translate-x-1/2 transform break-words rounded-lg bg-gray-500 px-2 py-1 text-sm text-white opacity-0 shadow-md transition-opacity duration-200 group-hover:opacity-100">
        {tooltip}
      </div>
    </div>
  ) : (
    button
  )
}
