import React, { useContext, useEffect, useRef } from 'react'
import {
  getStorage,
  ref,
  list,
  uploadBytesResumable,
  getDownloadURL,
  deleteObject,
  listAll,
} from 'firebase/storage'
import { useAuth } from './AuthContext'
import { useNotification } from './NotificationContext'
import { createUserStorage } from '@/utils/firebaseUtils'

const StorageContext = React.createContext('')

export function useStorage() {
  return useContext(StorageContext)
}

export const StorageProvider = ({ children }) => {
  const storage = getStorage()
  const userStorageRef = useRef(null)
  const { currentUser } = useAuth()
  const { addNotification } = useNotification()

  async function getUploadUrl(uploadTask) {
    return await getDownloadURL(uploadTask.ref)
  }

  async function handleUpload(storageRef, file) {
    const uploadTask = uploadBytesResumable(storageRef, file)
    uploadTask.on(
      'state_changed',
      function next(snapshot) {
        let progress = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        )
        // TODO - add progress bar to UI?
      },

      function error(err) {
        switch (err.code) {
          case 'storage/unauthorized':
            addNotification({
              id: Date.now(),
              message: `User doesn't have permission to access the object`,
              type: 'info',
            });
            break;
          case 'storage/canceled':
            addNotification({
              id: Date.now(),
              message: 'User canceled the upload',
              type: 'info',
            });
            break;
          case 'storage/unknown':
            addNotification({
              id: Date.now(),
              message: `Unknown error occurred, inspect ${err.serverResponse}`,
              type: 'error',
            });
            break;
        }
      },
      function complete() { }
    )
    return uploadTask
  }

  /**
 * Asynchronously deletes an audio file from the storage.
 * 
 * @param {string} id - The unique identifier for the audio to be deleted.
 * @returns {boolean} - Returns true if the audio is successfully deleted, otherwise returns false.
 */
  async function deleteAudioStorage(id) {
    const audioStorageRef = ref(storage, `audios/${currentUser.uid}/${id}`)
    try {
      await deleteObject(audioStorageRef);
      return true;
    } catch (err) {
      console.error("Delete Storage Error:", err);
      addNotification({
        title: 'Audio Delete Failed',
        message: 'Audio has failed to delete.',
        type: 'error',
      });
      return false;
    }
  }

  async function deleteUserStorage() {
    try {
  
      // Check if the storage exists first before trying to delete
      const listResult = await listAll(userStorageRef.current);
  
      // Deleting all items in the folder
      const deletePromises = listResult.items.map((itemRef) => {
        return deleteObject(itemRef);
      });
  
      // Wait for all files to be deleted
      await Promise.all(deletePromises);
  
      return true;
    } catch (error) {
      console.error("Error deleting user storage:", error);
      return false;
    }
  }


  // function createUserStorage() {
  //   userStorageRef.current = ref(storage, `audios/${currentUser.uid}`)
  //   return userStorageRef
  // }

  async function doesUserStorageExist() {
    try {
      const result = await list(userStorageRef.current, { maxResults: 1 })
      return result.items.length > 0 || result.prefixes.length > 0
    } catch (error) {
      if (error.code === 'storage/object-not-found') {
        return false
      } else {
        // Other errors (e.g., network issues, permission issues)
        addNotification({
          id: Date.now(),
          message: 'An error occurred. Please try again later.',
          type: 'error',
        })
      }
    }
  }

  function createStorageRef(timestamp) {
    return ref(storage, `audios/${currentUser.uid}/${timestamp}`)
  }

  function getStorageRef(id) {
    return ref(storage, `audios/${currentUser.uid}/${id}`)
  }


  async function addAudioToStorage(file, timestamp) {
    const audioStorageRef = createStorageRef(timestamp);
    const uploadTask = await handleUpload(audioStorageRef, file);
    const url = await getUploadUrl(uploadTask);

    return url; // Can get uploadTask from here
  }


  useEffect(() => {
    if (currentUser) {
      userStorageRef.current = createUserStorage(currentUser.uid);
    }
  }, [currentUser])

  const value = {
    userStorageRef,
    createStorageRef,
    getStorageRef,
    getUploadUrl,
    handleUpload,
    addAudioToStorage,
    deleteAudioStorage,
    deleteUserStorage,
    doesUserStorageExist,
  }

  return (
    <StorageContext.Provider value={value}>{children}</StorageContext.Provider>
  )
}
