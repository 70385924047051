import React, { useState } from 'react';
import { Button } from '@/components/common/Button';
import { sendContentToEmail } from '@/utils/helpers/contentHelper';
import { useAuth } from 'context/AuthContext';
import { useNotification } from 'context/NotificationContext';

function SendContentEmail({ content, onClose }) {
    const [emailTo, setEmailTo] = useState('');
    const [emailError, setEmailError] = useState(null);
    const { currentUser } = useAuth();
    const { addNotification } = useNotification()


    const isValidEmail = (email) => {
        // Simple regex to validate emails.
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailPattern.test(email);
    }

    const handleSendContent = async (event) => {
        event.preventDefault();

        if (!isValidEmail(emailTo)) {
            setEmailError('Please enter a valid email.');
            return;
        }
        try {
            await sendContentToEmail(content, emailTo, currentUser);
            addNotification({
                id: Date.now(),
                message: 'Content sent successfully',
                type: 'success',
            });

            setEmailTo(''); 
            onClose();
        } catch (error) {
            addNotification({
                id: Date.now(),
                message: 'Content failed to send',
                type: 'error',
            });
        }
    };

    return (
        <div className="p-4">
            <h2 className="text-xl font-bold mb-4">Send Content to Email</h2>
            <input
                type="email"
                placeholder="Enter your email"
                className="border rounded px-2 py-1 w-full mb-4"
                value={emailTo}
                onChange={e => setEmailTo(e.target.value)}
            />
            {emailError && <p className="text-red-500 mb-4">{emailError}</p>}
            <Button onClick={(e) => handleSendContent(e)}>
                Send Content
            </Button>
        </div>
    );
}

export default SendContentEmail;