import { convertServerTimestampToDate } from '@/utils/helpers/serverHelpers'

export function isNested(obj) {
  for (const key in obj) {
    if (typeof obj[key] === "object" && obj[key] !== null) {
      return true;
    }
  }
  return false;
}

export function convertObjToArray(obj) {
  if (!obj) return []

  let arr = []
  const keys = Object.keys(obj)

  for (let i = 0; i < keys.length; i++) {
    arr.push(obj[keys[i]])
  }

  return arr
}

export function isNothing(obj) {
  return obj === null || obj === undefined
}

export function isEmpty(obj) {
  return isNothing(obj) || obj === ""
}

export function isObjectEmpty(obj) {
  return isEmpty(obj) || Object.keys(obj).length === 0
}

export function findById(data, id) {
  if (!data) return null

  return data.find((item) => item.id === id)
}
 
function isObject(obj) {
  return typeof obj === "object" && obj !== null
}

function hasKeys(obj) {
  return Object.keys(obj).length > 0
}

function mapAudioFeed(feed) {
  return feed.map(
    ({ id, name, description, size, type, duration, url, modified }) => ({
      id,
      name,
      description,
      size,
      audio: {
        src: url,
        type: type,
        duration: duration,
      },
      modified: {
        time: convertServerTimestampToDate(modified?.time),
        by: modified?.by,
        name: modified?.name,
      },
    })
  )
}

export const ObjectHelper = {
  isNested,
  convertObjToArray,
  isNothing,
  isEmpty,
  isObjectEmpty,
  findById,
  isObject,
  hasKeys,
  mapAudioFeed
}