import React from 'react'
import { Button } from '../common/Button'

export default function FeatureRequest() {
  return (
    <>
      <h3 className="text-lg font-medium text-gray-800">
        Request a new Content Type
      </h3>
      <form
        action="#"
        method="POST"
        className="mt-6 grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-8"
      >
        <div className="sm:col-span-2">
          <label
            htmlFor="subject"
            className="block text-sm font-medium text-gray-800"
          >
            Content type
          </label>
          <div className="mt-1">
            <input
              type="text"
              name="subject"
              id="subject"
              className="block w-full rounded-md border-gray-300 py-3 px-4 text-gray-800 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
            />
          </div>
        </div>
        <div className="sm:col-span-2">
          <div className="flex justify-between">
            <label
              htmlFor="message"
              className="block text-sm font-medium text-gray-800"
            >
              Describe your request
            </label>
            <span id="message-max" className="text-sm text-gray-500">
              Max. 500 characters
            </span>
          </div>
          <div className="mt-1">
            <textarea
              id="message"
              name="message"
              rows={4}
              className="block w-full rounded-md border-gray-300 py-3 px-4 text-gray-800 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
              aria-describedby="message-max"
              defaultValue={''}
            />
          </div>
        </div>
        <div className="sm:col-span-2 sm:flex sm:justify-end">
          <Button variant="solid" color="indigo">
            Submit
          </Button>
        </div>
      </form>
    </>
  )
}
