import React, { useState, useEffect } from 'react'
import Feedback from '@/components/modals/Feedback'
import FeatureRequest from '@/components/modals/FeatureRequest'
import Upgrade from '../modals/Upgrade'
import DeleteAudio from '../modals/DeleteAudio'
import ConfirmNavigation from '../modals/ConfirmNavigation'
import RecordingRetakeConfirmation from '../modals/RecordingRetakeConfirmation'
import SendContentEmail from '../modals/SendContentEmail'
import ShareContentToLinkedIn from '../modals/ShareContentToLinkedIn'
import DeleteAccount from '../modals/DeleteAccount'
import CancelSubscription from '../modals/CancelSubscription'
import ResumeSubscription from '../modals/ResumeSubscription'
import ManageCookies from '../modals/ManageCookies'
import { XCircleIcon } from '@heroicons/react/24/outline'
import { classNames } from '@/utils/helpers/classNames'
import { useAuth } from '../../../context/AuthContext'

export default function Modal({ ...props }) {
  const { component } = props
  const [showModal, setShowModal] = useState(false)
  const [modal, setModal] = useState(null)
  const { payment } = useAuth()
  const [initialized, setInitialized] = useState(false) // Track initialization

  const handleKeyDown = (event) => {
    if (component.closable === false) return
    if (event.key === 'Escape') {
      handleModalClose()
    }
  }

  const handleModalClose = () => {
    if (component.closable === false) return
    setShowModal(false)
    if (props.onCloseModal) {
      props.onCloseModal()
    }
  }

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown)
    return () => {
      document.removeEventListener('keydown', handleKeyDown)
    }
  }, [])

  useEffect(() => {
    switch (component.name) {
      case 'Feedback':
        setModal(<Feedback />)
        break
      case 'Feature':
        setModal(<FeatureRequest />)
        break
      case 'Upgrade':
        setModal(<Upgrade />)
        break
      case 'Delete':
        setModal(<DeleteAudio onClick={handleModalClose} />)
        break
      case 'DeleteAccount':
        setModal(<DeleteAccount onClick={handleModalClose} content={props} />)
        break
      case 'CancelSubscription':
        setModal(
          <CancelSubscription onClick={handleModalClose} content={props} />
        )
        break
      case 'ResumeSubscription':
        setModal(
          <ResumeSubscription onClick={handleModalClose} content={props} />
        )
        break
      case 'ConfirmNavigation':
        setModal(<ConfirmNavigation onClick={handleModalClose} />)
        break
      case 'RecordingRetakeConfirmation':
        setModal(<RecordingRetakeConfirmation onClick={handleModalClose} />)
        break
      case 'SendContentEmail':
        setModal(
          <SendContentEmail
            content={props.content}
            onClose={handleModalClose}
          />
        )
        break
      case 'ShareContentToLinkedIn':
        setModal(
          <ShareContentToLinkedIn
            content={props.content}
            onClose={handleModalClose}
          />
        )
        break
      case 'ManageCookies':
        setModal(
          <ManageCookies content={props.content} onClick={handleModalClose} />
        )
        break
      default:
        return
    }

    if (initialized && component.direct) {
      setShowModal(true)
    } else {
      setInitialized(true)
    }
  }, [component, initialized])

  return (
    <>
      {!component.direct && !component.hasOwnership && (
        <div
          key={component.name}
          onClick={() => setShowModal(true)}
          className={classNames(
            component.current
              ? `font-bold text-${component.color ?? 'indigo'}-600`
              : `text-${component.color ?? 'gray'}-600 hover:bg-${
                  component.color ?? 'gray'
                }-50 hover:text-${component.color ?? 'gray'}-800 bg-${
                  component.color ? component.color : ''
                }-50`,
            `group cursor-pointer items-center rounded-md px-2 py-2 text-center text-sm font-medium ${component.name}`,
            component.className ?? 'flex'
          )}
        >
          {component.icon && (
            <component.icon
              className={classNames(
                component.current
                  ? `font-bold text-${component.color ?? 'indigo'}-600`
                  : `text-${component.color ?? 'gray'}-600 hover:bg-${
                      component.color ?? 'gray'
                    }-50 hover:text-${component.color ?? 'gray'}-800 bg-${
                      component.color ?? 'gray'
                    }-50`,
                'mr-3 h-6 w-6 flex-shrink-0'
              )}
              aria-hidden="true"
            />
          )}
          <span
            className={classNames(
              component.current
                ? `text-${component.color ?? 'indigo'}-600`
                : `text-${component.color}-800 group-hover:text-${
                    component.color ?? 'gray'
                  }-500`,
              'mr-3 h-6 w-6 flex-shrink-0'
            )}
            aria-hidden="true"
          >
            {component.buttonText ? component.buttonText : component.name}
          </span>
        </div>
      )}

      {showModal && (
        <>
          <div className="fixed bottom-0 left-0 right-0 top-0 z-50 flex cursor-default items-center justify-center overflow-hidden outline-none focus:outline-none">
            {component.hasOwnership ? (
              <div>{modal}</div>
            ) : (
              <div className="relative h-5/6 min-w-[36rem] overflow-y-auto rounded-lg shadow-sm">
                {payment !== null && (
                  <XCircleIcon
                    onClick={handleModalClose}
                    className="absolute right-0 top-0 z-10 mr-4 mt-4 h-6 w-6 cursor-pointer rounded-4xl text-gray-400 hover:text-gray-600"
                  />
                )}
                <div className="max-h-10/12 relative mx-auto max-w-7xl rounded-lg border-0 bg-white px-6 pb-20 pt-10 shadow-lg outline-none focus:outline-none lg:px-8">
                  {modal}
                </div>
              </div>
            )}
          </div>
          <div
            className="fixed inset-0 z-40 mt-0 bg-black opacity-25"
            onClick={handleModalClose}
          ></div>
        </>
      )}
    </>
  )
}
