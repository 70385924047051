import React, {
  useEffect,
  useContext,
  useState,
  useRef,
  useCallback,
} from 'react'
import {
  doc,
  updateDoc,
  arrayUnion,
  onSnapshot,
  collection,
  query,
  deleteDoc,
  getDoc,
  where,
} from 'firebase/firestore'
import { db } from '../firebase'
import { useAuth } from './AuthContext'
import { useNotification } from './NotificationContext'
import { convertServerTimestampToDate } from '@/utils/helpers/serverHelpers'
import { useRouter } from 'next/router'

const AnalysisContext = React.createContext(null)

export function useAnalysis() {
  const context = useContext(AnalysisContext)
  if (!context) {
    throw new Error('useAnalysis must be used within an AnalysisProvider')
  }
  return context
}

export function AnalysisProvider({ children }) {
  const router = useRouter()
  const currentTimestamp = `${Date.now()}`
  const analysisRef = useRef(null)
  const analysisSubCollectionRef = useRef(null)
  const { currentUser } = useAuth()
  const { addNotification } = useNotification()
  const [analysis, setAnalysis] = useState(null)
  const [analysisIdentifier, setAnalysisIdentifier] = useState(null)
  const [refsReady, setRefsReady] = useState(false)
  const [listeningTo, setListeningTo] = useState(null)
  const unsubscribeRef = useRef(null)

  const mapAnalysisFeed = useCallback(
    (feed) => {
      return feed.map(
        ({
          id,
          modified,
          analysis,
          transcript_summarised,
          embeddings_available,
        }) => {
          const defaultModified = {
            time: currentTimestamp,
            by: null,
            analysis: null,
            name: null,
          }

          return {
            id,
            modified: {
              time: modified?.time
                ? convertServerTimestampToDate(modified.time)
                : defaultModified.time,
              by: modified?.by || defaultModified.by,
              analysis:
                analysis ||
                getAnalysisData(modified?.analysis || defaultModified.analysis),
              name: modified?.name || defaultModified.name,
            },
            transcript:
              analysis?.transcript || modified?.analysis?.transcript || '',
            transcript_summarised: transcript_summarised || null,
            embeddings_available: embeddings_available ?? false,
          }
        }
      )
    },
    [currentTimestamp]
  )

  const getAnalysis = useCallback(
    async (analysisId) => {
      if (!refsReady || !analysisSubCollectionRef.current) {
        return
      }

      try {
        const docRef = doc(analysisSubCollectionRef.current, analysisId)
        const docSnap = await getDoc(docRef)

        if (docSnap.exists()) {
          const data = docSnap.data()
          const mappedData = mapAnalysisFeed([data]).sort((a, b) => {
            if (!a.modified.time) return 0
            return new Date(a.modified.time) - new Date(b.modified.time)
          })
          setAnalysis(mappedData)
        } else {
          setAnalysis(null)
        }
      } catch (error) {
        setAnalysis(null)
      }
    },
    [mapAnalysisFeed, setAnalysis, refsReady]
  )

  const getAnalysisData = useCallback((analysis) => {
    if (!analysis) return {}

    return Object.keys(analysis).reduce((obj, key) => {
      if (key.toLowerCase().includes('speaker')) {
        obj[key] = analysis[key]
      }
      return obj
    }, {})
  }, [])

  const deleteAnalysis = useCallback(
    async (id) => {
      if (!analysisSubCollectionRef.current) return false

      const specificDocRef = doc(analysisSubCollectionRef.current, id)

      try {
        await deleteDoc(specificDocRef)
        addNotification({
          id: Date.now(),
          message: 'Analysis deleted',
          type: 'success',
        })
        return true
      } catch (error) {
        addNotification({
          id: Date.now(),
          message: 'Error deleting analysis',
          type: 'error',
        })
        return false
      }
    },
    [addNotification]
  )

  const deleteAnalysisDocument = useCallback(async () => {
    if (!analysisRef.current) return false

    try {
      const docSnapshot = await getDoc(analysisRef.current)
      if (!docSnapshot.exists()) {
        return true
      }

      await deleteDoc(analysisRef.current)
      return true
    } catch (error) {
      return false
    }
  }, [])

  const analysisExists = useCallback(
    (audioId) => {
      if (!analysis) return null
      return analysis.find((item) => item.audioId === audioId)
    },
    [analysis]
  )

  const updateAnalysis = useCallback(
    async (newAnalysisObj, index) => {
      if (!analysis || !analysisRef.current) return

      const updatedAnalysis = [...analysis]
      updatedAnalysis[index] = { ...updatedAnalysis[index], ...newAnalysisObj }

      try {
        await updateDoc(analysisRef.current, {
          [newAnalysisObj.audioId]: updatedAnalysis,
        })
        setAnalysis(updatedAnalysis)
        addNotification({
          id: Date.now(),
          message: 'Analysis updated',
          type: 'success',
        })
      } catch (error) {
        addNotification({
          id: Date.now(),
          message: error.message,
          type: 'error',
        })
      }
    },
    [analysis, addNotification]
  )

  const addAnalysis = useCallback(
    async (newAnalysisObj) => {
      if (!analysisRef.current) return

      try {
        await updateDoc(analysisRef.current, {
          [newAnalysisObj.audioId]: arrayUnion(newAnalysisObj),
        })
        setAnalysis((prev) => [...(prev || []), newAnalysisObj])
        addNotification({
          id: Date.now(),
          message: 'Analysis added',
          type: 'success',
        })
      } catch (error) {
        addNotification({
          id: Date.now(),
          message: error.message,
          type: 'error',
        })
      }
    },
    [addNotification]
  )

  const listenToAnalysis = useCallback(
    (analysisId) => {
      if (!analysisSubCollectionRef.current || listeningTo === analysisId)
        return

      // Unsubscribe from previous listener if exists
      if (unsubscribeRef.current) {
        unsubscribeRef.current()
        unsubscribeRef.current = null
      }

      const collectionQuery = query(
        analysisSubCollectionRef.current,
        where('id', '==', analysisId)
      )

      unsubscribeRef.current = onSnapshot(
        collectionQuery,
        (querySnapshot) => {
          if (!querySnapshot.empty) {
            const data = querySnapshot.docs.map((doc) => doc.data())
            const mappedData = mapAnalysisFeed(data).sort((a, b) => {
              if (!a.modified.time) return 0
              return new Date(a.modified.time) - new Date(b.modified.time)
            })
            setAnalysis(mappedData)
          } else {
            setAnalysis(null)
          }
          setListeningTo(analysisId)
        },
        (error) => {
          setAnalysis(null)
          setListeningTo(null)
        }
      )

      return () => {
        if (unsubscribeRef.current) {
          unsubscribeRef.current()
          unsubscribeRef.current = null
        }
      }
    },
    [listeningTo, mapAnalysisFeed, setAnalysis, setListeningTo]
  )

  useEffect(() => {
    if (currentUser && currentUser.uid) {
      analysisRef.current = doc(db, 'analysis', currentUser.uid)
      analysisSubCollectionRef.current = collection(
        analysisRef.current,
        'analyses'
      )
      setRefsReady(true)
    } else {
      setRefsReady(false)
    }
    
    return () => {
      if (unsubscribeRef.current) {
        unsubscribeRef.current()
        unsubscribeRef.current = null
      }
    }
  }, [currentUser])

  useEffect(() => {
    if (
      router.query.audio &&
      router.query.audio !== analysisIdentifier &&
      refsReady
    ) {
      getAnalysis(router.query.audio)
    }
  }, [router.query.audio, refsReady])

  useEffect(() => {
    if (analysisIdentifier && refsReady) {
      // listenToAnalysis(analysisIdentifier)
      getAnalysis(analysisIdentifier)
    }

    if (analysis === null && unsubscribeRef.current) {
      unsubscribeRef.current()
      unsubscribeRef.current = null
      setListeningTo(null)
    }
  }, [analysisIdentifier, refsReady])

  const value = {
    analysis,
    setAnalysis,
    getAnalysis,
    deleteAnalysis,
    updateAnalysis,
    addAnalysis,
    analysisExists,
    analysisIdentifier,
    setAnalysisIdentifier,
    deleteAnalysisDocument,
  }

  return (
    <AnalysisContext.Provider value={value}>
      {children}
    </AnalysisContext.Provider>
  )
}
