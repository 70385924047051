import React, { useState, useEffect } from 'react'
import Modal from './Modal'
import { Button } from './Button'
import { CookiesHelper } from '../../utils/helpers/cookiesHelper' // ensure correct path
import { ObjectHelper } from '../../utils/helpers/objectHelper' // ensure correct path

export default function Cookies() {
  const categories = [
    {
      name: 'Essential Cookies',
      active: true,
      isAlwaysActive: true,
      description: `These cookies are necessary for the website to function and cannot be switched off in our systems. They are usually only set in response to actions made by you which amount to a request for services, such as setting your privacy preferences, logging in or filling in forms. You can set your browser to block or alert you about these cookies, but some parts of the site will not then work. These cookies do not store any personally identifiable information.`,
    },
    {
      name: 'Performance Cookies',
      active: false,
      isAlwaysActive: false,
      description: `These cookies help us track and tally visits and traffic sources so that we can measure and optimize the site's performance. They enable us to determine which pages are the most and least frequented, and observe how visitors interact with the site. The data gathered by these cookies is consolidated, making it anonymous. If you opt not to enable these cookies, we won't be aware of your visit to the site and will lack the necessary data to enhance its performance.`,
    },
    {
      name: 'Targeting Cookies',
      active: false,
      isAlwaysActive: false,
      description: `These cookies might be placed on our site by our advertising partners. They could be utilized by these companies to compile a profile of your interests and present you with pertinent ads on other websites. While they don't store personal information directly, they operate by uniquely identifying your browser and internet-connected device. Should you choose not to accept these cookies, you may encounter advertising that is less tailored to your preferences.`,
    },
    {
      name: 'Social Media Cookies',
      active: false,
      isAlwaysActive: false,
      description: `These cookies are implemented by various social media services integrated into the site to allow you to share our content with your peers and networks. They have the ability to track your browser activity across different sites and accumulate a profile of your interests. This might influence the content and communications you encounter on other sites you browse. If you decide against enabling these cookies, you might be unable to utilize or view these sharing functionalities.`,
    },
  ]

  const defaultPreference = categories.map((category) => {
    return {
      name: category.name,
      active: category.active,
    }
  })
  const [showModal, setShowModal] = useState(false)
  const [savedPreferences, setSavedPreferences] = useState(() => {
    return CookiesHelper.getCookie('userPreferences')
  })
  const [showComponent, setShowComponent] = useState(!savedPreferences)
  const [cookiePreferences, setCookiePreferences] = useState(() => {
    return savedPreferences ? JSON.parse(savedPreferences) : null
  })

  useEffect(() => {
    if (!ObjectHelper.isEmpty(cookiePreferences)) {
      CookiesHelper.setCookie(
        'userPreferences',
        JSON.stringify(cookiePreferences)
      )
      setShowComponent(false)
    }
  }, [cookiePreferences])

  const manageCookiesModal = {
    name: 'ManageCookies',
    current: true,
    direct: true,
    hasOwnership: true,
    onRejectNonEssentialCookies: rejectNonEssentialCookies,
    onAcceptAllCookies: acceptAllCookies,
    onAcceptUserPreferences: acceptUserPreferences,
    cookiePreferences: cookiePreferences,
    handleModalClose: handleModalClose,
    categories,
  }

  function handleModalClose() {
    setShowModal(false)
  }

  function acceptAllCookies() {
    const acceptAllPreference = categories.map((category) => {
      return {
        name: category.name,
        active: true,
      }
    })

    setCookiePreferences(acceptAllPreference)
  }

  function rejectAllCookies() {
    const rejectAllPreference = categories.map((category) => {
      return {
        name: category.name,
        active: false,
      }
    })

    setCookiePreferences(rejectAllPreference)
  }

  function acceptUserPreferences(userPreferences) {
    setCookiePreferences(userPreferences)
  }

  function rejectNonEssentialCookies() {
    setCookiePreferences(defaultPreference)
  }

  if (!showComponent) {
    return null
  }

  return (
    <>
      {showModal && (
        <Modal
          key="Delete"
          component={manageCookiesModal}
          onCloseModal={handleModalClose}
          content={manageCookiesModal}
        />
      )}
      <div className="pointer-events-none fixed inset-x-0 bottom-0 px-6 pb-6">
        <div className="pointer-events-auto ml-auto max-w-xl rounded-xl bg-white p-6 shadow-lg ring-1 ring-gray-900/10">
          <p className="text-sm leading-6 text-gray-900">
            By continuing to browse our site, you are agreeing to our use of
            cookies in accordance with OpenSpeech's cookie practices. See our{' '}
            <Button simpleLink={true} href="/cookie-policy">
              cookie policy
            </Button>
            .
          </p>
          <div className="mt-4 flex items-center gap-x-5">
            <Button onClick={acceptAllCookies} variant="solid" color="black" className="text-sm sm:text-base">
              Accept all
            </Button>
            <Button onClick={rejectAllCookies} variant="solid" color="black" className="text-sm sm:text-base">
              Reject all
            </Button>
            <Button
              onClick={() => setShowModal(true)}
              variant="empty"
              color="black"
            >
              Manage
            </Button>
          </div>
        </div>
      </div>
    </>
  )
}
