import React, { useState, useEffect } from 'react'
import { Button } from '@/components/common/Button'
import { classNames } from '@/utils/helpers/classNames'
import { useRouter } from 'next/router'
import { StripeHelper } from 'stripeHelper'
import { ChevronRightIcon, CheckCircleIcon } from '@heroicons/react/24/outline'
import { useProduct } from 'context/ProductContext'
import { useNotification } from 'context/NotificationContext'
import { useAuth } from 'context/AuthContext'
import { StringHelper } from '@/utils/helpers/stringHelper'
import { RadioGroup } from '@headlessui/react'
import { NumberHelper } from '@/utils/helpers/numberHelper'

export function PricingPlan({ pricing }) {
  const route = useRouter()
  const [frequency, setFrequency] = useState(null)

  const { getPricesCollectionData, setRegisteringProduct, getFrequency } =
    useProduct()
  const { addNotification } = useNotification()
  const { currentUser, payment, logout, subscription } = useAuth()
  const pricingModal = route.pathname !== '/pricing'

  async function handlePricingSubscription(prodId) {
    const prices = await getPricesCollectionData(prodId)
    const priceId = prices.find(
      (price) => price.interval === getFrequency(frequency.value)
    ).id
    
    if (!currentUser) {
      setRegisteringProduct(priceId)
      route.push('/register')

      return
    }

    if (currentUser && currentUser.uid) {
      try {
        const checkoutResult = await StripeHelper.createCheckoutSession(
          priceId,
          currentUser.uid
        )

        if (checkoutResult && checkoutResult.success) {
          const upgradeSubscription =
            await StripeHelper.upgradeSubscriptionItems(
              currentUser.stripeId,
              priceId
            )
          // TODO: Update subscription id in database
          if (upgradeSubscription.success && checkoutResult.success) {
            addNotification({
              id: Date.now(),
              message: 'Successfully upgraded subscription.',
              type: 'success',
            })
          }
        }
      } catch (error) {
        addNotification({
          id: Date.now(),
          message: `An error occurred. Please try again later. ${error.message}}`,
          type: 'error',
        })
      }
    }
  }

  const handleButtonClick = () => {
    const escEvent = new KeyboardEvent('keydown', {
      key: 'Escape',
      code: 'Escape',
      keyCode: 27,
      charCode: 27,
    })

    document.dispatchEvent(escEvent)
  }

  useEffect(() => {
    if (pricing && pricing.frequencies.length > 0) {
      setFrequency(pricing.frequencies[0])
    }
  }, [pricing])

  if (!pricing) return null

  return (
    <>
      <>
        {pricingModal && !payment && (
          <>
            <div className="mx-auto max-w-2xl text-center lg:max-w-4xl">
              <p className="mt-2 text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl">
                The right price for you, whoever you are
              </p>
            </div>
            <p className="mx-auto mt-2 max-w-2xl text-center text-sm text-gray-600">
              Our pricing plans cater to teams of all sizes, making it easy for
              everyone to find a plan that fits their needs and budget.
            </p>
          </>
        )}
      </>
      <div className="relative mx-auto mt-10 grid max-w-md grid-cols-1 gap-x-8 gap-y-8 lg:mx-0 lg:-mb-14 lg:max-w-none lg:grid-cols-4">
        <div
          className="lg:bg-white-800/80 hidden lg:absolute lg:bottom-0 lg:top-4 lg:block"
          aria-hidden="true"
        />
        {pricingModal && payment && (
          <div>
            <h2 className="text-3xl font-semibold">Upgrade Your Plan</h2>
            <div className="flex sm:h-40 lg:h-96 flex-col justify-between">
              <div>
                {/* <div className="relative mt-4 flex">
              <RadioGroup
                value={frequency}
                onChange={(option) => {
                  setFrequency(option)
                }}
                className="grid grid-cols-2 gap-x-1 rounded-full bg-white/5 p-1 text-center text-xs font-semibold leading-5 ring-1 ring-inset ring-gray-200"
              >
                <RadioGroup.Label className="sr-only">
                  Payment frequency
                </RadioGroup.Label>
                {pricing?.frequencies.map((option) => (
                  <RadioGroup.Option
                    key={option.value}
                    value={option}
                    className={({ checked }) =>
                      classNames(
                        checked ? 'bg-indigo-500 text-white' : '',
                        'cursor-pointer rounded-full px-2.5 py-1'
                      )
                    }
                  >
                    <span>{option.label}</span>
                  </RadioGroup.Option>
                ))}
              </RadioGroup>
              <span className="absolute -mt-4 ml-28 inline-flex h-6 items-center rounded-full bg-green-500 px-2.5 py-0.5 text-xs font-medium text-white">
                Save 20%
              </span>
            </div> */}
                <p className="mt-4 text-sm">
                  Boost your audio services! Upgrade your workspace for advanced
                  tools: audio transcription, sentiment analysis and increased
                  storage. Elevate your audio projects now!
                </p>
              </div>

              <span
                className="group mb-6 flex cursor-pointer items-center text-xs hover:text-gray-700"
                onClick={() => handleButtonClick()}
              >
                Continue with current
                <b className="mx-1">
                  {StringHelper.capitalize(currentUser?.productPlan)}
                </b>
                plan
                <ChevronRightIcon className="ml-2 h-3 w-3" />
              </span>
            </div>
          </div>
        )}
        {pricing &&
          pricing.tiers.map((tier) => (
            <div
              key={tier.id}
              className={classNames(
                tier.featured
                  ? 'z-10 bg-white shadow-xl ring-2 ring-indigo-600'
                  : 'ring-1 ring-gray-900/10 lg:bg-transparent ',
                'relative flex flex-col justify-between rounded-2xl'
              )}
            >
              <div className="p-4">
                <div className="flex justify-between">
                  <h2
                    id={tier.id}
                    className="text-lg font-semibold leading-6 tracking-wider"
                  >
                    {tier.name}
                  </h2>
                  {/* TODO: Make this static 'starter' dynamic */}
                  {tier.name === 'Starter' && !payment && (
                    <p className="w-max rounded-full bg-indigo-600/10 px-2.5 py-1 text-xs font-semibold leading-5 text-indigo-600">
                      7 days free trial
                    </p>
                  )}
                </div>

                <p className="my-2 text-sm leading-normal text-gray-600">
                  {tier.description}
                </p>
                <div className="flex flex-col sm:flex-row sm:items-end sm:justify-between lg:flex-col lg:items-stretch">
                  <div className="flex items-center gap-x-4">
                    <p className="text-3xl font-bold tracking-tight">
                      $
                      {NumberHelper.roundToCeiling(
                        tier?.price[frequency?.value]
                      )}
                      /{frequency?.value === 'monthly' ? 'mo' : 'yr'}
                    </p>
                  </div>
                  <p className="text-xs">
                    {tier.name === 'Free' || tier.name === 'Business' ? (
                      <br />
                    ) : (
                      `per user ${frequency?.value}`
                    )}
                  </p>
                </div>
                <Button
                  className="mb-4 mt-4 block w-full bg-indigo-600 px-4 py-3 text-center text-sm font-semibold text-white hover:bg-indigo-700"
                  onClick={() => handlePricingSubscription(tier.id)}
                >
                  {tier.name === 'Business' ? 'Contact Sales' : 'Continue'}
                </Button>
                <div className="flow-root">
                  <ul role="list" className="text-sm">
                    {tier.mainFeatures.map((mainFeature) => (
                      <li key={mainFeature} className="flex gap-x-3">
                        <CheckCircleIcon
                          className={classNames(
                            tier.featured
                              ? 'text-indigo-600'
                              : 'text-green-500',
                            'h-6 w-5 flex-none'
                          )}
                          aria-hidden="true"
                        />
                        {mainFeature}
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          ))}
        {pricingModal && (subscription?.status !== 'active' && subscription?.status !== 'trialing') && (
          <div className="mt-6 flex items-center text-sm">
            Different account?
            <a
              className="ml-1 cursor-pointer text-indigo-500"
              onClick={() => logout()}
            >
              Sign out
            </a>
          </div>
        )}
      </div>
    </>
  )
}
