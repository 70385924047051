function convertSnakeCaseToCamelCase(str) {
  if (!str || typeof str !== 'string') {
    return ''
  }

  return str.replace(/([-_][a-z])/g, (group) =>
    group.toUpperCase().replace('-', '').replace('_', '')
  )
}

function convertSnakeCaseToNormal(str) {
  if (!str || typeof str !== 'string') {
    return ''
  }

  return str
    .replace(/^[a-z]/, (match) => match.toUpperCase())
    .replace(/([-_][a-z])/g, (group) => ' ' + group.charAt(1).toUpperCase())
    .trim()
}

function capitalize(str) {
  if (!str || typeof str !== 'string') {
      return str;
  }
  return str.charAt(0).toUpperCase() + str.slice(1);
}

function speakerNameFormatter(speaker) {
  const speakerArr = speaker.split('_')
  const speakerName =
    speakerArr[0].charAt(0) + speakerArr[0].slice(1).toLowerCase()
  const speakerNumber = (parseInt(speakerArr[1]) + 1).toString()

  return `${speakerName} ${speakerNumber}`
}


export const StringHelper = {
  convertSnakeCaseToCamelCase,
  convertSnakeCaseToNormal,
  speakerNameFormatter,
  capitalize
}
