import { Fragment } from 'react';
import Link from 'next/link';
import { Popover, Transition } from '@headlessui/react';
import clsx from 'clsx';

import { Button } from '@/components/common/Button';
import { Container } from '@/components/layouts/Container';
import { Logo } from '@/components/common/Logo';
import { NavLink } from '@/components/common/NavLink';

const MobileNavLink = ({ href, children }) => (
  <Popover.Button as={Link} href={href} className="block w-full p-2">
    {children}
  </Popover.Button>
);

const MobileNavigation = () => (
  <Popover>
    <Popover.Button
      className="relative z-10 flex h-8 w-8 items-center justify-center [&:not(:focus-visible)]:focus:outline-none"
      aria-label="Toggle Navigation"
    >
      {({ open }) => (
        <svg
          aria-hidden="true"
          className="h-3.5 w-3.5 overflow-visible stroke-indigo-700"
          fill="none"
          strokeWidth={2}
          strokeLinecap="round"
        >
          <path
            d="M0 1H14M0 7H14M0 13H14"
            className={clsx(
              'origin-center transition',
              open && 'scale-90 opacity-0'
            )}
          />
          <path
            d="M2 2L12 12M12 2L2 12"
            className={clsx(
              'origin-center transition',
              !open && 'scale-90 opacity-0'
            )}
          />
        </svg>
      )}
    </Popover.Button>
    <Transition.Root>
    <Transition.Child
          as={Fragment}
          enter="duration-150 ease-out"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="duration-150 ease-in"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Popover.Overlay className="fixed inset-0 z-40 bg-indigo-300/50" />
        </Transition.Child>
        <Transition.Child
          as={Fragment}
          enter="duration-150 ease-out"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="duration-100 ease-in"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95"
        >
          <Popover.Panel
            as="div"
            className="absolute inset-x-0 top-full z-50 mt-4 flex origin-top flex-col rounded-2xl bg-white p-4 text-lg tracking-tight text-indigo-900 shadow-xl ring-1 ring-indigo-900/5"
          >
            <MobileNavLink href="/about">About</MobileNavLink>
            <MobileNavLink href="/pricing">Pricing</MobileNavLink>
            <MobileNavLink href="/contact">Contact</MobileNavLink>
            <MobileNavLink href="/login">Get Started</MobileNavLink>
          </Popover.Panel>
        </Transition.Child>
    </Transition.Root>
  </Popover>
);


export const Header = ({currentUser}) => (
  <header className="py-6">
    <Container>
      <nav className="relative flex items-center justify-between">
        <Link href="/" aria-label="Home">
          <Logo className="h-6 w-auto md:h-8" />
        </Link>
        <div className="flex items-center gap-x-3">
          <div className="hidden md:flex">
            <NavLink href="/about">About</NavLink>
            <NavLink href="/pricing">Pricing</NavLink>
            <NavLink href="/contact">Contact</NavLink>
          </div>
          <Button
            href={currentUser ? `/dashboard` : `/register`}
            className="invisible rounded-md px-6 py-3 md:visible"
          >
            { currentUser ? `Dashboard` : `Get Started`}
          </Button>
          <div className="-mr-1 md:hidden">
            <MobileNavigation />
          </div>
        </div>
      </nav>
    </Container>
  </header>
);