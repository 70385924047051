export const VALID_FILE_TYPES = [
    'audio/wav',   // Uncompressed Waveform Audio File Format, commonly used in professional audio
    'audio/mpeg',  // MPEG Audio Stream, Layer III (MP3), commonly used for consumer audio streaming and storage
    'audio/mp3',    // Non-standard MIME type for MP3
    // 'audio/aac',   // Advanced Audio Codec, often used in mobile devices
    // 'audio/mp4',   // MPEG-4 Audio Layer, commonly used for audio and video in various applications
    // 'audio/ogg',   // Ogg container with audio codecs like Vorbis or Opus, often used in open-source applications
    // 'audio/flac',  // Free Lossless Audio Codec, used for lossless audio compression
    // 'audio/x-aiff', // Audio Interchange File Format, used in Apple products
    'audio/webm',  // WebM container with Opus or Vorbis audio codec, commonly used in web applications
    'video/mp4'
  ];
  