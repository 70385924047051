import React, { useContext, useState, useEffect, useRef, useCallback, useMemo } from 'react';
import { doc, collection, getDocs, onSnapshot } from 'firebase/firestore';
import { db } from '../firebase';
import { useAuth } from './AuthContext';
import { StripeHelper } from 'stripeHelper';
import { useNotification } from './NotificationContext';
import { ObjectHelper } from '../src/utils/helpers/objectHelper';
import { CreditConstants } from '../src/constants/CreditConstants';
import { saveData, getData } from '../src/utils/helpers/localStorageHelper';

const ProductContext = React.createContext('');

export function useProduct() {
  return useContext(ProductContext);
}

export function ProductProvider({ children }) {
  const userRef = useRef(null);
  const productHotFix = useRef(null);

  const { currentUser, subscription } = useAuth();
  const { addNotification } = useNotification();

  const [product, setProduct] = useState(null);
  const [products, setProducts] = useState([]);
  const [registeringProduct, setRegisteringProduct] = useState(null);
  const [stripeSubscription, setStripeSubscription] = useState(null);

  const getFrequency = useCallback((frequency) => {
    return frequency === 'monthly' ? 'month' : 'year';
  }, []);

  const getProducts = useCallback(async () => {
    const cachedProducts = getData('products');

    if (cachedProducts) {
      setProducts(cachedProducts);
      productHotFix.current = cachedProducts;
      return;
    }

    if (products.length > 0) return;

    try {
      const querySnapshot = await getDocs(collection(db, 'products'));
      const filteredDocs = querySnapshot.docs.filter(doc => doc.data().metadata?.firebaseEnv === 'production');
      const productsData = filteredDocs.map(doc => ({ ...doc.data(), id: doc.id }));

      saveData('products', productsData);
      setProducts(productsData);
      productHotFix.current = productsData;

      if (!currentUser && productsData.length && ObjectHelper.isEmpty(registeringProduct)) {
        const starterProduct = productsData.find(product => product.role === 'starter');
        if (starterProduct) {
          const prices = await getPricesCollectionData(starterProduct.id);
          const monthlyPrice = prices.find(price => price.interval === getFrequency('monthly'));
          if (monthlyPrice) {
            setRegisteringProduct(monthlyPrice.id);
          }
        }
      }
    } catch (error) {
      setProducts([]);
      addNotification({
        id: Date.now(),
        message: `Failed to get products. ${error.message}`,
        type: 'error',
      });
    }
  }, [products.length, currentUser, registeringProduct, getFrequency, addNotification]);

  const getPricesCollectionData = useCallback(async (docId) => {
    try {
      const querySnapshot = await getDocs(collection(db, 'products', docId, 'prices'));
      return querySnapshot.docs.map(doc => ({ ...doc.data(), id: doc.id }));
    } catch (error) {
      addNotification({
        id: Date.now(),
        message: 'Failed to get prices collection data.',
        type: 'error',
      });
      return [];
    }
  }, [addNotification]);

  const transformProductData = useCallback(async (products) => {
    if (!products) return;

    const tierOrder = ['Starter', 'Basic', 'Creator', 'Professional'];

    const transformedTiers = await Promise.all(products.map(async (product) => {
      const { id, name, metadata, description, features } = product;
      const pricesData = await getPricesCollectionData(id);
      const priceStructure = pricesData.reduce((acc, price) => {
        const interval = price.recurring.interval;
        const priceValue = price.unit_amount / 100;
        if (interval === 'month') {
          acc.monthly = priceValue.toFixed(2);
        } else if (interval === 'year') {
          acc.annually = priceValue.toFixed(2);
        }
        return acc;
      }, { monthly: 'N/A', annually: 'N/A' });

      const mainFeatures = [
        `${metadata.audioCredit} minutes audio usage per month`,
        `${metadata.contentCredit} credits to generate content per month`,
      ];

      return {
        name,
        id,
        mostPopular: metadata?.mostPopular || false,
        href: '#',
        featured: metadata?.featured || false,
        description: description || 'Description not available.',
        price: priceStructure,
        mainFeatures: mainFeatures || [],
        features: features || [],
      };
    }));

    const sortedTiers = transformedTiers.sort((a, b) => tierOrder.indexOf(a.name) - tierOrder.indexOf(b.name));
    let allFeatures = new Set();
    sortedTiers.forEach(tier => {
      tier.features.forEach(feature => allFeatures.add(feature.name));
    });
    allFeatures = [...allFeatures];

    const featuresWithTiers = allFeatures.map(featureName => {
      const tiersWithFeature = {};
      sortedTiers.forEach(tier => {
        tiersWithFeature[tier.name] = tier.features.some(f => f.name === featureName);
      });

      return {
        name: featureName,
        tiers: tiersWithFeature,
      };
    });

    const sections = [
      {
        name: 'Features',
        features: featuresWithTiers,
      },
    ];

    return {
      frequencies: [
        { value: 'monthly', label: 'Monthly' },
        { value: 'annually', label: 'Annually' },
      ],
      tiers: sortedTiers,
      sections,
    };
  }, [getPricesCollectionData]);

  const getProductByRole = useCallback((role) => {
    let productRole = null;

    if (!products || products.length === 0) {
      productRole = productHotFix.current.find(product => product.role === role);
    } else {
      productRole = products.find(product => product.role === role);
    }

    return productRole;
  }, [products]);

  const getProductPlan = useCallback((plan) => {
    return plan ? CreditConstants.find(item => item.name === plan) : CreditConstants[0];
  }, []);

  const getProductIntervalPrice = useCallback((product, interval) => {
    if (!product) return null;
    return product.find(price => price.interval === interval);
  }, []);

  const cancelSubscription = useCallback(async (userSubscriptionId) => {
    try {
      const cancel = await StripeHelper.cancelSubscription(subscription?.subscriptionId ?? userSubscriptionId);

      if (cancel.success) {
        addNotification({
          id: Date.now(),
          message: 'Subscription cancelled',
          type: 'success',
        });
        return cancel.success;
      } else {
        addNotification({
          id: Date.now(),
          message: cancel.message,
          type: 'error',
        });
        return null;
      }
    } catch (error) {
      addNotification({
        id: Date.now(),
        message: error.message,
        type: 'error',
      });
      return null;
    }
  }, [subscription, addNotification]);

  const resumeSubscription = useCallback(async (userSubscriptionId) => {
    try {
      const resume = await StripeHelper.resumeSubscription(subscription?.subscriptionId ?? userSubscriptionId);

      addNotification({
        id: Date.now(),
        message: 'Subscription resumed',
        type: 'success',
      });

      return resume.success;
    } catch (error) {
      addNotification({
        id: Date.now(),
        message: error.message,
        type: 'error',
      });
      return null;
    }
  }, [subscription, addNotification]);

  const pauseSubscription = useCallback(async () => {
    try {
      const pause = await StripeHelper.pauseSubscription(currentUser.subscriptionId);
      return pause.success;
    } catch (error) {
      addNotification({
        id: Date.now(),
        message: error.message,
        type: 'error',
      });
      return null;
    }
  }, [currentUser, addNotification]);

  const getProductSubscription = useCallback((subscriptionId) => {
    return StripeHelper.retrieveSubscription(subscriptionId);
  }, []);

  const handleCheckoutSession = useCallback(async (uid) => {
    let defaultProductPriceId = null;

    if (ObjectHelper.isEmpty(registeringProduct)) {
      const product = getProductByRole('starter');
      const prices = await getPricesCollectionData(product.id);
      defaultProductPriceId = prices.find(x => x.interval === getFrequency('monthly')).id;
    }

    await StripeHelper.createCheckoutSession(registeringProduct || defaultProductPriceId, uid);
  }, [registeringProduct, getProductByRole, getPricesCollectionData, getFrequency]);

  const deleteStripeCustomer = useCallback(async (user) => {
    try {
      const deleting = await StripeHelper.deleteCustomer(user.stripeId);
      return deleting.success;
    } catch (error) {
      addNotification({
        id: Date.now(),
        message: error.message,
        type: 'error',
      });
    }
  }, [addNotification]);

  useEffect(() => {
    getProducts();

    if (currentUser?.uid) {
      userRef.current = doc(db, 'users', currentUser.uid);
      setProduct(currentUser.productPlan);
    }
  }, [currentUser, getProducts]);

  const value = useMemo(() => ({
    product,
    products,
    getProducts,
    getFrequency,
    getProductPlan,
    getProductByRole,
    pauseSubscription,
    registeringProduct,
    stripeSubscription,
    resumeSubscription,
    deleteStripeCustomer,
    transformProductData,
    setRegisteringProduct,
    handleCheckoutSession,
    getPricesCollectionData,
    cancelSubscription,
  }), [
    product,
    products,
    getProducts,
    getFrequency,
    getProductPlan,
    getProductByRole,
    pauseSubscription,
    registeringProduct,
    stripeSubscription,
    resumeSubscription,
    deleteStripeCustomer,
    transformProductData,
    handleCheckoutSession,
    getPricesCollectionData,
    cancelSubscription,
  ]);

  return (
    <ProductContext.Provider value={value}>{children}</ProductContext.Provider>
  );
}
