// firebaseUtils.jsx
import { ref, getStorage } from 'firebase/storage';
import { auth, db } from '../../firebase';
import { doc, setDoc } from 'firebase/firestore';

// Utility function to create user storage
export const createUserStorage = (userId) => {
    const storage = getStorage();
    const userStorageRef = ref(storage, `audios/${userId}`);
    return userStorageRef;
};

// Utility function to add user specific documents
export const addUserSpecificDocs = async (userId) => {
    try {
        await setDoc(doc(db, 'audios', userId), {});
        await setDoc(doc(db, 'contents', userId), {});
        await setDoc(doc(db, 'analysis', userId), {});
    } catch (error) {
        console.error("Error in adding user specific docs:", error);
        // TODO: Handle error appropriately (e.g., notification or logging)
    }
};
