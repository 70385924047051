import React from 'react'
import { ExclamationCircleIcon } from '@heroicons/react/20/solid'
import { useNotification } from '../../../context/NotificationContext'

export default function RecordingRetakeConfirmation({ ...props }) {
  const { addNotification } = useNotification()

  function handleCloseModal() {
    props.onClick()
  }

  function leaveAudio() {
    handleCloseModal()
    addNotification({
      title: 'Recording Stopped',
      message: 'Your recording has been stopped.',
      type: 'info',
    })
  }

  return (
    <div className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
      <div className="sm:flex sm:items-start">
        <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-blue-100 sm:mx-0 sm:h-10 sm:w-10">
          <ExclamationCircleIcon
            className="h-6 w-6 text-blue-600"
            aria-hidden="true"
          />
        </div>
        <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
          <div className="mt-2">
            <h2>Heads up, your recording will be lost!</h2>
            <p className="text-sm text-gray-500">
              If you want to keep your recording, close this modal by pressing
              Cancel. Then, you can press Stop to finish the recording or Resume
              to continue recording.{' '}
            </p>
          </div>
        </div>
      </div>
      <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
        <button
          type="button"
          className="inline-flex w-full justify-center rounded-md border border-transparent bg-blue-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
          onClick={handleCloseModal}
        >
          Cancel
        </button>
        <button
          type="button"
          className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:mt-0 sm:w-auto sm:text-sm"
          onClick={() => leaveAudio()}
        >
          Confirm
        </button>
      </div>
    </div>
  )
}
