import { loadStripe } from '@stripe/stripe-js'
import axios from 'axios'

async function getStripe() {
  return await loadStripe(process.env.NEXT_PUBLIC_API_STRIPE_PUBLIC_KEY)
}

async function createCheckoutSession(productPriceId, userId) {
  try {
    const data = await callAPI(
      'POST',
      process.env.NEXT_PUBLIC_STRIPE_CREATE_CHECKOUT_SESSION,
      {
        userId: userId,
        priceId: productPriceId,
        successUrl: `${window.location.origin}/dashboard`,
        cancelUrl: window.location.origin,
      }
    )
    
    const { id: sessionId } = data
    const stripe = await getStripe()

    stripe.redirectToCheckout({ sessionId });
  } catch (error) {
    console.error('Error creating checkout session', error)
    return null
  }
}

async function callAPI(method, endpoint, params) {
  try {
    const config = {
      method: method,
      url: endpoint,
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    }

    if (method === 'POST' || method === 'PUT') {
      config.data = new URLSearchParams(params)
    } else {
      config.params = params
    }

    const response = await axios(config)
    return response.data
  } catch (error) {
    console.error(`Error on ${endpoint}:`, error.response?.data?.error)
    return null
  }
}

async function retrieveCustomer(customerID) {
  const data = await callAPI(
    'POST',
    process.env.NEXT_PUBLIC_STRIPE_RETRIEVE_CUSTOMER,
    { customerID }
  )

  return data?.customer_info
}

async function modifyCustomer(customerID, metadata) {
  await callAPI('POST', process.env.NEXT_PUBLIC_STRIPE_MODIFY_CUSTOMER, {
    customerID,
    metadata: JSON.stringify(metadata),
  })
}

async function deleteCustomer(customerID) {
  const data = await callAPI(
    'POST',
    process.env.NEXT_PUBLIC_STRIPE_DELETE_CUSTOMER,
    {
      customerID,
    }
  )

  return data
}

async function createSubscription(customerID, planID) {
  const data = await callAPI(
    'POST',
    process.env.NEXT_PUBLIC_STRIPE_CREATE_SUBSCRIPTION,
    {
      customerID,
      planID,
    }
  )

  return data?.create_subscription_info
}

async function updateSubscription(subscriptionRole, subscriptionID) {
  const data = await callAPI(
    'POST',
    process.env.NEXT_PUBLIC_STRIPE_UPDATE_SUBSCRIPTION,
    {
      subscriptionRole,
      subscriptionID
    }
  )

  return data
}

async function upgradeSubscriptionItems(customerID, newPlanID) {
  const data = await callAPI(
    'POST',
    process.env.NEXT_PUBLIC_STRIPE_UPGRADE_SUBSCRIPTION,
    {
      customerID,
      newPlanID,
    }
  )

  return data
}

async function resumeSubscription(subscriptionID) {
  const data = await callAPI(
    'POST',
    process.env.NEXT_PUBLIC_STRIPE_RESUME_SUBSCRIPTION,
    {
      subscriptionID,
    }
  )

  return data
}

async function pauseSubscription(subscriptionID) {
  const data = await callAPI(
    'POST',
    process.env.NEXT_PUBLIC_STRIPE_PAUSE_SUBSCRIPTION,
    {
      subscriptionID,
    }
  )

  return data
}

async function cancelSubscription(subscriptionID) {
  const data = await callAPI(
    'POST',
    process.env.NEXT_PUBLIC_STRIPE_CANCEL_SUBSCRIPTION,
    {
      subscriptionID,
    }
  )

  return data
}

async function retrieveSubscription(subscriptionID) {
  const data = await callAPI(
    'POST',
    process.env.NEXT_PUBLIC_STRIPE_RETRIEVE_SUBSCRIPTION,
    { subscriptionID }
  )
  return data?.retrieve_subscription_info
}

async function listActiveProducts() {
  const data = await callAPI(
    'GET',
    process.env.NEXT_PUBLIC_STRIPE_LIST_PRODUCTS,
    { active: true }
  )
  return data?.data
}

async function createPaymentMethod(paymentDetails) {
  const data = await callAPI(
    'POST',
    process.env.NEXT_PUBLIC_STRIPE_CREATE_PAYMENT_METHOD,
    paymentDetails
  )
  return data?.payment_method;
}

async function retrievePaymentMethod(customerID, paymentMethodID) {
  const endpoint = `${process.env.NEXT_PUBLIC_STRIPE_RETRIEVE_PAYMENT_METHOD}/${customerID}/${paymentMethodID}`;
  const data = await callAPI('GET', endpoint);
  return data?.payment_method;
}

async function updatePaymentMethod(paymentMethodID, customerID, updateDetails) {
  const endpoint = `${process.env.NEXT_PUBLIC_STRIPE_UPDATE_PAYMENT_METHOD}/${paymentMethodID}/${customerID}`;
  const data = await callAPI('PUT', endpoint, updateDetails);
  return data?.updated_payment_method;
}

async function listCustomerPaymentMethods(customerID, paymentDetails = {}) {
  const endpoint = `${process.env.NEXT_PUBLIC_STRIPE_LIST_CUSTOMER_PAYMENT_METHODS}/${customerID}`;
  const data = await callAPI('GET', endpoint, paymentDetails);
  return data?.payment_methods;
}

export const StripeHelper = {
  modifyCustomer,
  deleteCustomer,
  retrieveCustomer,
  pauseSubscription,
  listActiveProducts,
  createSubscription,
  updateSubscription,
  resumeSubscription,
  cancelSubscription,
  createPaymentMethod,
  updatePaymentMethod,
  retrieveSubscription,
  createCheckoutSession,
  retrievePaymentMethod,
  upgradeSubscriptionItems,
  listCustomerPaymentMethods
}
