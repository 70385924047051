import React, { useState, useEffect, memo, Fragment } from 'react'
import { Transition } from '@headlessui/react'
import {
  CheckCircleIcon,
  ExclamationTriangleIcon,
  InformationCircleIcon,
  ExclamationCircleIcon,
} from '@heroicons/react/24/solid'
import { XMarkIcon, ArrowLongRightIcon } from '@heroicons/react/24/outline'
import { useNotification } from '../../../context/NotificationContext'
import { Button } from '@/components/common/Button'

export const NotificationIcon = memo(function NotificationIcon({ type }) {
  switch (type) {
    case 'success':
      return (
        <CheckCircleIcon
          className="h-6 w-6 text-green-400"
          aria-hidden="true"
        />
      )
    case 'error':
      return (
        <ExclamationTriangleIcon
          className="h-6 w-6 text-red-400"
          aria-hidden="true"
        />
      )
    case 'info':
      return (
        <InformationCircleIcon
          className="h-6 w-6 text-indigo-600"
          aria-hidden="true"
        />
      )
    case 'warning':
      return (
        <ExclamationCircleIcon
          className="h-6 w-6 text-yellow-400"
          aria-hidden="true"
        />
      )
    default:
      return null
  }
})

export function NotificationItem({ notification, removeNotification }) {
  const [show, setShow] = useState(true)
  const [progress, setProgress] = useState(() => {
    // Calculate initial progress based on the time elapsed since creation
    const timeElapsed = Date.now() - notification.id
    const initialProgress = (timeElapsed / notification.fixedDuration) * 100
    return Math.min(initialProgress, 100) // Ensure it doesn't exceed 100%
  })

  useEffect(() => {
    let interval
    if (notification.fixed && notification.fixedDuration) {
      const remainingDuration =
        notification.fixedDuration - (Date.now() - notification.id)
      const step = 50
      const totalSteps = remainingDuration / step
      let currentStep = (progress / 100) * totalSteps

      interval = setInterval(() => {
        currentStep += 1
        setProgress((currentStep / totalSteps) * 100)

        if (currentStep >= totalSteps) {
          clearInterval(interval)
          removeNotification(notification.id)
        }
      }, step)
    }

    return () => {
      if (interval) {
        clearInterval(interval)
      }
    }
  }, [notification, removeNotification, progress])

  function handleClose() {
    setShow(false)
    removeNotification(notification.id)
  }
  // Handle click on the notification message (Used for navigating to audio page)
  function handleClick(e) {
    if (notification.link) {
      e.stopPropagation()

      // Extract the numeric ID from the URL
      const url = new URL(notification.link)
      const pathSegments = url.pathname.split('/')
      // The double pop() ensures we get the ID even if the URL ends with a slash.
      const audioID = pathSegments.pop() || pathSegments.pop()

      // Construct the new URL to navigate to
      const destinationUrl = `${window.location.origin}/audios/${audioID}`

      // Use window.location.href for navigation
      window.location.href = destinationUrl

      handleClose()
    }
  }

  return (
    <Transition
      show={show}
      as={Fragment}
      enter="transform ease-out duration-300 transition"
      enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
      enterTo="translate-y-0 opacity-100 sm:translate-x-0"
      leave="transition ease-in duration-100"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
    >
      <div className="pointer-events-auto mb-5 ml-5 w-full max-w-sm overflow-hidden rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5">
        <div className="pt-4 pl-4 pr-4">
          <div className="flex items-start">
            <div className="flex-shrink-0">
              <NotificationIcon type={notification.type} />
            </div>
            <p className="text-sm font-medium text-gray-800 ml-3">
              {notification.message}
            </p>
            {notification.canUserDismiss ? (
              <div className="ml-4 flex flex-shrink-0">
                <button
                  type="button"
                  className="inline-flex text-gray-400 hover:text-gray-500 focus:outline-none"
                  onClick={handleClose}
                >
                  <span className="sr-only">Close</span>
                  <XMarkIcon className="h-5 w-5" aria-hidden="true" />
                </button>
              </div>
            ) : null}
          </div>
        </div>
        <div className='relative float-right'>
          {notification.link && (
            <Button
              onClick={(e) => {
                handleClick(e)
              }}
              variant="empty"
              color="green"
            >
              View Audio
              <ArrowLongRightIcon className="h-5 w-5" aria-hidden="true" />
            </Button>
          )}
        </div>
        {notification.fixed && notification.type !== 'success' && (
          <>
            <div className="mx-2 mb-1 h-1 overflow-hidden rounded bg-gray-200">
              <div
                className='h-1 rounded transition-all duration-300 ease-linear bg-indigo-500'
                style={{ width: `${progress}%` }}
              ></div>
            </div>
          </>
        )}
      </div>
    </Transition>
  )
}

export default function Notification() {
  const {
    notifications,
    removeNotification,
    removeAllNotifications,
    addHoveredNotification,
  } = useNotification()

  const generateUniqueId = () => `${Date.now()}-${Math.random()}`

  return (
    <div
      className="fixed right-5 top-5 z-50 w-[26rem] space-y-2 overflow-hidden"
      style={{
        maxHeight: '500px',
        background: 'linear-gradient(transparent 60%, rgba(255,255,255,0))',
      }}
    >
      {notifications.length > 1 ? (
        <div className="mr-3 flex justify-end">
          <button
            className="text-sm text-gray-500 hover:text-gray-700"
            onClick={() => removeAllNotifications()}
          >
            Clear all
          </button>
        </div>
      ) : null}
      {notifications.map((notification) => (
        <NotificationItem
          key={generateUniqueId()}
          notification={notification}
          removeNotification={removeNotification}
        />
      ))}
    </div>
  )
}
