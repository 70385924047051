import Cookies from 'js-cookie';

function setCookie(name, value, options = {}) {
  Cookies.set(name, value, {
    path: '/',
    secure: true,
    sameSite: 'strict',
    expires: 30,
    ...options,
  });
}

function getCookie(name) {
  return Cookies.get(name);
}

function getCookies() {
  return Cookies.get();
}

function removeCookie(name, options = {}) {
  Cookies.remove(name, { path: '/', ...options });
}

function removeCookies(options = {}) {
  const cookies = getCookies();

  Object.keys(cookies).forEach((cookie) => {
    removeCookie(cookie, options);
  });
}

export const CookiesHelper = {
  getCookie,
  setCookie,
  getCookies,
  removeCookie,
  removeCookies
};
