
export function  isTokenExpiring (issuedAt, expiresIn) {
    // If issuedAt is not available, assume the token is expired
    if (!issuedAt) {
      return true;
    }

    const expirationTime = issuedAt + expiresIn;
    const currentTime = Math.floor(Date.now() / 1000);
    const timeRemaining = expirationTime - currentTime;

    // Calculate remaining days
    const daysRemaining = Math.ceil(timeRemaining / 86400); // Convert seconds to days

    // Check if the token is expiring within 3 days
    const threeDaysBeforeExpiration = expirationTime - 259200; // 3 days in seconds
    return currentTime >= threeDaysBeforeExpiration;
  };


  // Helper function to check if the token is expired
  const isTokenExpired = (issuedAt, expiresIn) => {
    // If issuedAt is not available, assume the token is expired
    if (!issuedAt) {
      return true;
    }

    const expirationTime = issuedAt + expiresIn;
    const currentTime = Math.floor(Date.now() / 1000);
    return currentTime >= expirationTime;
  };