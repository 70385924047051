import React, { useEffect, useContext, useState, useRef, useCallback, useMemo } from 'react'
import {
  doc,
  serverTimestamp,
  setDoc,
  onSnapshot,
  getDocs,
  query,
  collection,
  where,
  deleteDoc,
  orderBy,
  limit,
  startAfter,
  startAt,
  getDoc,
} from 'firebase/firestore'
import { db } from '../firebase'
import { useNotification } from './NotificationContext'
import { ObjectHelper } from '@/utils/helpers/objectHelper'
import { useAuth } from './AuthContext'
import { useStorage } from './StorageContext'
import { saveData, getData } from '@/utils/helpers/localStorageHelper'

const AudioContext = React.createContext('')

export function useAudio() {
  return useContext(AudioContext)
}

export function AudiosProvider({ children }) {
  const audioRef = useRef(null)
  const audioSubCollectionRef = useRef(null)
  const { addNotification } = useNotification()
  const { deleteAudioStorage } = useStorage()
  const { currentUser } = useAuth()
  const [audio, setAudio] = useState(null)
  const [audioFiles, setAudioFiles] = useState([])
  const [loading, setLoading] = useState(true)
  const [changes, setChanges] = useState(false)
  const [totalAudio, setTotalAudio] = useState(0)
  const [lastVisible, setLastVisible] = useState(null) // for next page
  const [firstVisible, setFirstVisible] = useState(null) // for previous page
  const [pageRefs, setPageRefs] = useState([])

  async function deleteAudioDB(id) {
    const specificDocRef = doc(audioSubCollectionRef.current, id)
    try {
      await deleteDoc(specificDocRef)
      return true
    } catch (error) {
      console.error(error)
      addNotification({
        title: 'Audio Delete Failed',
        message: 'Audio has failed to delete.',
        type: 'error',
      })
      return false
    }
  }

  async function deleteAudioDocument() {
    try {
      // Check if the document exists
      const docSnapshot = await getDoc(audioRef.current)
      if (!docSnapshot.exists()) {
        return true
      }
      await deleteDoc(audioRef.current)
      return true
    } catch (error) {
      console.error('Error deleting Audio in database:', error)
      return false
    }
  }

  async function deleteAudio(id) {
    let isAudioDBDeleted = false
    let isAudioStorageDeleted = false

    try {
      isAudioDBDeleted = await deleteAudioDB(id)
    } catch (err) {
      console.error('Error deleting audio from database:', err)
    }

    try {
      isAudioStorageDeleted = await deleteAudioStorage(id)
    } catch (err) {
      console.error('Error deleting audio from storage:', err)
    }

    if (isAudioDBDeleted && isAudioStorageDeleted) {
      const filteredAudio = audioFiles.filter((audio) => audio.id !== id)
      setAudioFiles(filteredAudio)
      saveData('audioFiles', filteredAudio)

      addNotification({
        title: 'Audio Deleted',
        message: 'Audio has been deleted successfully.',
        type: 'success',
      })
    } else {
      if (!isAudioDBDeleted) {
        addNotification({
          title: 'Audio Database Deletion Failed',
          message: 'Failed to delete the audio from the database.',
          type: 'error',
        })
      }
      if (!isAudioStorageDeleted) {
        addNotification({
          title: 'Audio Storage Deletion Failed',
          message: 'Failed to delete the audio from the storage.',
          type: 'error',
        })
      }
    }
  }

  function updateAudio(id, data) {
    setDoc(
      audioRef.current,
      {
        [id]: {
          ...data,
          modified: {
            time: serverTimestamp(),
            by: currentUser.uid,
            name: currentUser.displayName,
          },
        },
      },
      { merge: true }
    )
      .then(() => {
        addNotification({
          title: 'Audio Updated',
          message: 'Audio has been updated successfully.',
          type: 'success',
        })
      })
      .catch((err) => {
        addNotification({
          title: 'Audio Update Failed',
          message: 'Audio has failed to update.',
          type: 'error',
        })
      })
  }

  const audioChange = useCallback(() => {
    setChanges(prev => !prev)
  }, [])

  function reformatDateString(dateString) {
    if (!dateString) return
    const [datePart, timePart] = dateString.split(', ')
    const [day, month, year] = datePart.split('/')
    return `${month}/${day}/${year}, ${timePart}`
  }

  const sortArrayByModifiedTime = useMemo(() => (array) => {
    return array.sort((a, b) => {
      const dateA = new Date(reformatDateString(a.modified.time))
      const dateB = new Date(reformatDateString(b.modified.time))
      return dateB - dateA // for descending order
    })
  }, [])

  async function getAudio(id, useLocalStorage = false) {
    if (useLocalStorage) {
      const audio = getAudioFromLocalStorage(id)

      // If audio from local storage is null or undefined, get it from the database
      if (!audio) {
        return await getAudioFromDB(id)
      }

      return audio
    } else {
      return await getAudioFromDB(id)
    }
  }

  function getAudioFromLocalStorage(id) {
    const audiosArray = getData('audioFiles')
    const foundArray = audiosArray.find((audio) => audio.id === id)

    setAudio(foundArray)
  }

  function handleNext() {
    setPageRefs([...pageRefs, firstVisible])
    listenToAudioCollectionData('next')
  }

  function handlePrev() {
    const newPageRefs = [...pageRefs]
    const prevPageStartDoc = newPageRefs.pop()
    setPageRefs(newPageRefs)
    listenToAudioCollectionData('prev', prevPageStartDoc)
  }

  const searchStateAudio = useCallback((query) => {
    const searchQuery = query.toLowerCase()
    setAudioFiles(prevFiles => 
      prevFiles.filter((audio) => {
        const audioTitle = audio.title.toLowerCase()
        const audioDescription = audio.description.toLowerCase()
        const audioTags = audio.tags.map((tag) => tag.toLowerCase())
  
        return (
          audioTitle.includes(searchQuery) ||
          audioDescription.includes(searchQuery) ||
          audioTags.includes(searchQuery)
        )
      })
    )
  }, [])

  async function searchUserAudioCollection(text, limitResult = 10) {
    if (text === '') {
      listenToAudioCollectionData('initial', null, limitResult)
      return
    }

    try {
      const collectionQuery = query(
        audioSubCollectionRef.current,
        where('name', '>=', text),
        where('name', '<=', text + '\uf8ff'),
        orderBy('name'),
        orderBy('modified.time', 'desc'),
        limit(limitResult)
      )

      const querySnapshot = await getDocs(collectionQuery)
      const data = querySnapshot.docs.map((doc) => doc.data())
      const mappedDoc = ObjectHelper.mapAudioFeed(data)
      setAudioFiles(mappedDoc)
    } catch (err) {
      addNotification({
        title: 'Audio Search Failed',
        message: 'Audio has failed to search.',
        type: 'error',
      })
    }
  }

  async function getAudioFromDB(id) {
    if (!audioRef.current) return

    try {
      const q = query(audioSubCollectionRef.current, where('id', '==', id))
      const querySnapshot = await getDocs(q)
      const data = querySnapshot.docs.map((doc) => {
        return {
          ...doc.data(),
        }
      })
      const mappedDoc = ObjectHelper.mapAudioFeed(data)[0]

      setAudio(mappedDoc)
      return mappedDoc
    } catch (err) {
      addNotification({
        title: 'Audio Fetch Failed',
        message: 'Audio has failed to fetch.',
        type: 'error',
      })
    }

    setLoading(false)
  }

  // Return total audio count from state if useState is true
  // Otherwise, get total audio count from database
  function getTotalAudioCount(useState = true) {
    if (useState) {
      return totalAudio
    } else {
      let totalCount = 0
      const totalAudioCountQuery = query(audioSubCollectionRef.current)

      getDocs(totalAudioCountQuery).then((querySnapshot) => {
        totalCount = querySnapshot.size
        setTotalAudio(totalCount)
      })

      return totalCount
    }
  }

  function listenToAudioCollectionData(
    direction = 'initial',
    startDoc = null,
    limitResult = 10
  ) {
    if (!audioRef.current || !audioSubCollectionRef.current) {
      return;
    }
  
    let collectionQuery;
  
    if (direction === 'initial') {
      collectionQuery = query(
        audioSubCollectionRef.current,
        orderBy('modified.time', 'desc'),
        limit(limitResult)
      );
    } else if (direction === 'next' && lastVisible) {
      collectionQuery = query(
        audioSubCollectionRef.current,
        orderBy('modified.time', 'desc'),
        startAfter(lastVisible),
        limit(limitResult)
      );
    } else if (direction === 'prev' && startDoc) {
      collectionQuery = query(
        audioSubCollectionRef.current,
        orderBy('modified.time', 'desc'),
        startAt(startDoc),
        limit(limitResult)
      );
    }
  
    getTotalAudioCount(false);
  
    return onSnapshot(collectionQuery, (querySnapshot) => {
      if (!querySnapshot.empty) {
        const data = querySnapshot.docs.map((doc) => ({ ...doc.data() }));
        const mappedData = ObjectHelper.mapAudioFeed(data).sort((a, b) => {
          const timeA = a.modified?.time ? new Date(a.modified.time) : new Date(0);
          const timeB = b.modified?.time ? new Date(b.modified.time) : new Date(0);
          return timeB.getTime() - timeA.getTime();
        });
        const sortedDoc = sortArrayByModifiedTime(mappedData);
      
        setAudioFiles(sortedDoc);
        saveData('audioFiles', sortedDoc);
        
        // Batch these updates
        setLastVisible(querySnapshot.docs[querySnapshot.docs.length - 1]);
        setFirstVisible(querySnapshot.docs[0]);
      }
    });
  }

  useEffect(() => {
    let isMounted = true
    let unsubscribe

    if (currentUser && currentUser.uid) {
      audioRef.current = doc(db, 'audios', currentUser.uid)
      audioSubCollectionRef.current = collection(audioRef.current, 'audio')

      unsubscribe = listenToAudioCollectionData()
    } else {
      if (isMounted) {
        setAudioFiles(null)
        setAudio(null)
        setLoading(false)
      }
    }

    return () => {
      isMounted = false
      if (unsubscribe) unsubscribe()
    }
  }, [currentUser])

  const value = {
    audio,
    setAudio,
    getAudio,
    loading,
    setLoading,
    audioFiles,
    deleteAudioDB,
    deleteAudioStorage,
    deleteAudio,
    updateAudio,
    saveData,
    audioChange,
    changes,
    setChanges,
    deleteAudioDocument,
    getTotalAudioCount,
    handleNext,
    handlePrev,
    searchUserAudioCollection,
    searchStateAudio,
  }

  return (
    <AudioContext.Provider value={value}>
      {!loading && children}
    </AudioContext.Provider>
  )
}
