// TODO: Remove color from content type DB
// TODO: Create content_Type context and make content_Type have sub collections of types,
// so we can align user created content types to them - which we might want to  make a market
// place in the future {isActive, isPublic, createdBy, createdDate, modifiedBy, modifiedDate,
// creators, categories, tags, color, icon, description, name, id}
import React, { useEffect, useContext, useState, useRef, useCallback, useMemo } from 'react';
import {
  doc,
  getDoc,
  updateDoc,
  arrayRemove,
  onSnapshot,
  collection,
  getDocs,
  query,
  deleteDoc,
  setDoc
} from 'firebase/firestore';
import { onAuthStateChanged } from 'firebase/auth';
import { auth, db } from '../firebase';
import { saveData } from '@/utils/helpers/localStorageHelper';
import { useAuth } from './AuthContext';
import { useCredit } from './CreditContext';
import { useNotification } from './NotificationContext';

const ContentContext = React.createContext('');
const DATA_TYPE_TRANSCRIPT = 'transcript';
const DATA_TYPE_DATA = 'data';

export function useContent() {
  return useContext(ContentContext);
}

export function ContentProvider({ children }) {
  const contentRef = useRef(null);
  const contentTypesRef = useRef(null);
  const { currentUser, integrations } = useAuth();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);
  const [content, setContent] = useState(null);
  const [contents, setContents] = useState(null);
  const [selectedContent, setSelectedContent] = useState(null);
  const { updateCreditBalance } = useCredit();
  const { addNotification } = useNotification();
  const [contentTypes, setContentTypes] = useState([]);

  const deleteContent = useCallback(
    async (audioId, type) => {
      try {
        await updateDoc(contentRef.current, { [audioId]: arrayRemove(type) });
        addNotification({
          id: Date.now(),
          message: 'Content deleted',
          type: 'success',
        });
      } catch (error) {
        addNotification({
          id: Date.now(),
          message: error.message,
          type: 'error',
        });
      }
    },
    [addNotification]
  );

  const contentExists = useCallback(
    (audioId, type) => {
      if (!contents) return null;
      return contents[audioId]?.findIndex((item) => item.type === type);
    },
    [contents]
  );

  const handleContentUpload = useCallback(
    async (newContentObj, callback) => {
      if (!newContentObj || !contentRef.current) return;

      if (newContentObj.background) {
        delete newContentObj.background;
      }

      const audioContentDocRef = doc(contentRef.current, newContentObj.audioId);

      try {
        const docSnapshot = await getDoc(audioContentDocRef);

        if (!docSnapshot.exists) {
          await setDoc(audioContentDocRef, {}, { merge: true });
        }

        const existingContent = contents ? contents[newContentObj.audioId] : null;
        if (existingContent && existingContent[newContentObj.type]) {
          const existingCount = existingContent[newContentObj.type].count || 0;
          newContentObj.count = existingCount + 1;
          await updateContent(newContentObj, callback);
        } else {
          newContentObj.count = 1;
          await addContent(newContentObj, callback);
        }
      } catch (error) {
        addNotification({
          id: Date.now(),
          message: error.message,
          type: 'error',
        });
      }
    },
    [contents, addNotification]
  );

  async function updateContent(newContentObj, callback) {
    setLoading(true);
    const audioContentDocRef = doc(contentRef.current, newContentObj.audioId);

    try {
      await updateDoc(audioContentDocRef, {
        [`${newContentObj.type}`]: {
          type: newContentObj.type,
          content: newContentObj.content,
          count: newContentObj.count,
        },
      });
      setLoading(false);
      callback(newContentObj.content);
    } catch (error) {
      setLoading(false);
      addNotification({
        id: Date.now(),
        message: error.message,
        type: 'error',
      });
    }
  }

  async function addContent(newContentObj, callback) {
    setLoading(true);
    const audioContentDocRef = doc(contentRef.current, newContentObj.audioId);

    try {
      await setDoc(
        audioContentDocRef,
        {
          [`${newContentObj.type}`]: {
            type: newContentObj.type,
            content: newContentObj.content,
            count: 1,
          },
        },
        { merge: true }
      );
      setLoading(false);
      callback(newContentObj.content);
    } catch (error) {
      setLoading(false);
      addNotification({
        id: Date.now(),
        message: error.message,
        type: 'error',
      });
    }
  }

  async function deleteContentDocument() {
    try {
      const contentRefTemp = doc(db, 'contents', currentUser.uid);
      await deleteDoc(contentRefTemp);
      return true;
    } catch (error) {
      addNotification({
        id: Date.now(),
        message: error.message,
        type: 'error',
      });
      return false;
    }
  }

  async function fetchData(item, formData) {
    try {
      const response = await fetch(item.api, {
        method: 'POST',
        body: formData,
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      return await response.json();
    } catch (error) {
      addNotification({
        id: Date.now(),
        message: `Fetch error: ${error.message}`,
        type: 'error',
      });
      throw error;
    }
  }

  async function fetchTranscriptSummarised(audioId) {
    const analysisRef = doc(db, 'analysis', currentUser.uid);
    const analysisSubCollectionRef = collection(analysisRef, 'analyses');
    const analysisDocRef = doc(analysisSubCollectionRef, audioId);

    try {
      const analysisDoc = await getDoc(analysisDocRef);
      if (analysisDoc.exists()) {
        return analysisDoc.data().transcript_summarised;
      }
    } catch (error) {
      addNotification({
        id: Date.now(),
        message: `Error fetching analysis data: ${error.message}`,
        type: 'error',
      });
    }

    return null;
  }

  const shareContentToLinkedIn = async (contentText) => {
    const accessToken = integrations.access_token;
    const authorUrn = integrations.author_urn;

    const postBody = {
      contentText,
      accessToken,
      authorUrn,
    };

    try {
      const response = await fetch(process.env.NEXT_PUBLIC_LINKEDIN_POST_API, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(postBody),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const responseData = await response.json();

      addNotification({
        id: Date.now(),
        message: 'Content shared to LinkedIn successfully.',
        type: 'success',
      });

      return responseData;
    } catch (error) {
      console.error('Error sharing content to LinkedIn:', error);
      addNotification({
        id: Date.now(),
        message: `Error sharing content to LinkedIn: ${error.message}`,
        type: 'error',
      });
      return null;
    }
  };

  async function generateContent(item, data, audio, selectedPerspective, selectedTone) {
    if (currentUser.credits.content === 0) {
      addNotification({
        id: Date.now(),
        message: 'Not enough credits.',
        type: 'error',
      });
      setLoading(false);
      return;
    }

    if (!data || !audio) {
      addNotification({
        id: Date.now(),
        message: 'Invalid inputs to generateContent',
        type: 'error',
      });
      setLoading(false);
      return;
    }

    setLoading(true);

    const transcriptSummarised = await fetchTranscriptSummarised(audio.id);
    const data_type = transcriptSummarised ? DATA_TYPE_TRANSCRIPT : DATA_TYPE_DATA;

    const formData = new FormData();
    formData.append('data', JSON.stringify(data));
    formData.append('data_type', data_type);
    formData.append('perspective', selectedPerspective);
    formData.append('tone', selectedTone);

    if (transcriptSummarised) {
      formData.append('transcript_summarised', transcriptSummarised);
    }

    try {
      const content = await fetchData(item, formData);

      const newContentObj = {
        audioId: audio.id,
        type: item.id,
        content: content['data'],
      };

      handleContentUpload(newContentObj, (newContent) => {
        setContent({ content: newContent });
        saveData('content', JSON.stringify({ content: newContent }));
      });

      updateCreditBalance('content', 1);
    } catch (error) {
      addNotification({
        id: Date.now(),
        message: `Error generating content: ${error.message}`,
        type: 'error',
      });
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    const unsubscribeAuth = onAuthStateChanged(auth, (user) => {
      if (!user) {
        setContents(null);
        setContent(null);
        setLoading(false);
        return;
      }

      contentRef.current = collection(db, 'contents', user.uid, 'content');
      listenToContentDocs(user.uid);

      const contentTypesRef = collection(db, 'content_type');
      const queryActiveContentTypes = query(contentTypesRef);

      getDocs(queryActiveContentTypes)
        .then((snapshot) => {
          const fetchedContentTypes = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          setContentTypes(fetchedContentTypes);
        })
        .catch((error) => {
          addNotification({
            id: Date.now(),
            message: error.message,
            type: 'error',
          });
        });
    });

    return () => {
      unsubscribeAuth();
    };
  }, [auth, addNotification]);

  const listenToContentDocs = useCallback((uid) => {
    const unsubscribe = onSnapshot(contentRef.current, (querySnapshot) => {
      const allAudioContent = {};
      querySnapshot.forEach((docSnap) => {
        const audioId = docSnap.id;
        allAudioContent[audioId] = docSnap.data();
      });

      if (Object.keys(allAudioContent).length) {
        setContents(allAudioContent);
        saveData('contents', JSON.stringify(allAudioContent));
      }
      setLoading(false);
    });

    return unsubscribe;
  }, []);

  const contextValue = useMemo(
    () => ({
      loading,
      error,
      success,
      content,
      contents,
      selectedContent,
      deleteContent,
      updateContent,
      addContent,
      setSelectedContent,
      generateContent,
      shareContentToLinkedIn,
      contentExists,
      contentTypes,
      deleteContentDocument,
      setLoading,
      setContent,
    }),
    [
      loading,
      error,
      success,
      content,
      contents,
      selectedContent,
      deleteContent,
      updateContent,
      addContent,
      setSelectedContent,
      generateContent,
      shareContentToLinkedIn,
      contentExists,
      contentTypes,
      deleteContentDocument,
      setLoading,
      setContent,
    ]
  );

  return (
    <ContentContext.Provider value={contextValue}>
      {children}
    </ContentContext.Provider>
  );
}
