import { fileHelper } from './fileHelper'

export function htmlToMarkdown(html) {
  // Define a list of replacements for converting HTML to Markdown
  const replacements = [
    // Convert header tags to markdown
    { regex: /<h1[^>]*>(.*?)<\/h1>/g, replace: '# $1' },
    { regex: /<h2[^>]*>(.*?)<\/h2>/g, replace: '## $1' },
    { regex: /<h3[^>]*>(.*?)<\/h3>/g, replace: '### $1' },
    { regex: /<h4[^>]*>(.*?)<\/h4>/g, replace: '#### $1' },
    { regex: /<h5[^>]*>(.*?)<\/h5>/g, replace: '##### $1' },
    { regex: /<h6[^>]*>(.*?)<\/h6>/g, replace: '###### $1' },
    // Convert <p> tags to markdown
    { regex: /<\/p>\s*<p>/g, replace: '\n\n' },
    { regex: /<p[^>]*>/g, replace: '' },
    { regex: /<\/p>/g, replace: '\n\n' },
    // Convert <a> tags to markdown
    { regex: /<a[^>]*href="([^"]+)"[^>]*>(.*?)<\/a>/g, replace: '[$2]($1)' },
    // Convert <strong> and <b> tags to markdown
    { regex: /<strong[^>]*>(.*?)<\/strong>/g, replace: '**$1**' },
    { regex: /<b[^>]*>(.*?)<\/b>/g, replace: '**$1**' },
    // Convert <em> and <i> tags to markdown
    { regex: /<em[^>]*>(.*?)<\/em>/g, replace: '_$1_' },
    { regex: /<i[^>]*>(.*?)<\/i>/g, replace: '_$1_' },
    // Convert <ul> to markdown (unordered lists)
    { regex: /<li[^>]*>(.*?)<\/li>/g, replace: '\n* $1' },
    { regex: /<\/ul>/g, replace: '\n' },
    // Remove any leftover tags
    { regex: /<[^>]+>/g, replace: '' }
  ];

  // Apply each replacement in sequence
  for (let replacement of replacements) {
    html = html.replace(replacement.regex, replacement.replace);
  }

  return html.trim();
}

export function formatContentType(type) {
  return type.replace(/\s+/g, '_').toLowerCase()
}

export function copyContent(content) {
 // Convert HTML to Markdown
 const markdownVersion = htmlToMarkdown(content);

  navigator.clipboard.writeText(markdownVersion)
  // Toaster('Copied to clipboard', 'success')
}

export function downloadContent(content) {
  fileHelper.downloadPDF(content)

  // TODO: Add support for other content types
  // switch (type) {
  //   case 'text':
  //     // downloadText(content)
  //     break
  //   case 'pdf':
  //     downloadPDF(content)
  //     break
  //   default:
  //     downloadPDF(content)
  // }
}

export async function sendContentToEmail(content, emailTo, user) {
  const apiUrl = process.env.NEXT_PUBLIC_CONTENT_EMAIL_SENDER;

  const emailData = {
    content: content,
    emailTo: emailTo,
    firstName: user.firstName,
    lastName: user.lastName,
    senderEmail: user.email
  };

  const response = await fetch(apiUrl, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(emailData),
  });

  if (!response.ok) {
    throw new Error('Error sending content via email.');
  }

  return true;
}
