import Link from 'next/link'
import { useRouter } from 'next/router'
import { classNames } from '@/utils/helpers/classNames'

export function NavLink({ href, children }) {
  const router = useRouter()
  const isActive = router.pathname === href

  return (
    <Link
      href={href}
      className={classNames(
        isActive ? 'text-indigo-600' : 'text-gray-600',
        'transition-color text-sm mx-4 duration-100 hover:text-indigo-600'
      )}
    >
      {children}
    </Link>
  )
}
