import React, { Component } from 'react'
import Router from 'next/router'

// This component will display when a JavaScript errors occurs anywhere in the child component tree
class ErrorBoundary extends Component {
  state = { hasError: false, error: null, errorInfo: null } // Initialize state directly

  static getDerivedStateFromError(error) {
    return { hasError: true }
  }

  // used for logging errors to a service, and it catches errors in any components below and re-renders with a fallback UI
  componentDidCatch(error, errorInfo) {
    // Catch errors in any components below and re-render with error message
    this.setState({
      error: error,
      errorInfo: errorInfo,
    })
    // TODO: You can also log error messages to an error reporting service here - SENTRY
  }

  handleRefresh = () => {
    window.location.reload()
  }

  handleGoBack = () => {
    Router.back()
    setTimeout(() => {
      window.location.reload()
    }, 500)
  }

  render() {
    if (this.state.hasError) {
      return (
        <div className="flex min-h-screen items-center justify-center bg-gray-100 px-4 py-10">
          <div className="w-full max-w-2xl space-y-4 rounded-lg bg-white p-6 shadow-lg">
            <h2 className="text-2xl font-bold text-gray-800">
              Oops, something went wrong...
            </h2>
            <p className="text-gray-600">
              We're sorry for the inconvenience. Please try again later or{' '}
              <a
                href="mailto:contact@openspeech.io"
                className="cursor-pointer underline"
              >
                contact support
              </a>
              .
            </p>
            <details className="border border-r bg-gray-100 p-4 text-sm text-red-500">
              {this.state.error && this.state.error.toString()}
              <br />
              {this.state.errorInfo && this.state.errorInfo.componentStack}
            </details>
            <div className="flex justify-end space-x-4">
              <button
                onClick={this.handleRefresh}
                className="rounded bg-blue-500 px-4 py-2 text-white hover:bg-blue-600"
              >
                Refresh Page
              </button>
              <button
                onClick={this.handleGoBack}
                className="rounded bg-gray-300 px-4 py-2 text-gray-700 hover:bg-gray-400"
              >
                Go Back
              </button>
            </div>
          </div>
        </div>
      )
    }

    return this.props.children
  }
}

export default ErrorBoundary
