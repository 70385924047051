function formatTime(totalSeconds) {
  let hours = Math.floor(totalSeconds / 3600)
  let minutes = Math.floor((totalSeconds - hours * 3600) / 60)
  let seconds = Math.round(totalSeconds - hours * 3600 - minutes * 60)

  // pad the minutes and seconds with leading zeros, if required
  minutes = (minutes < 10 ? '0' : '') + minutes
  seconds = (seconds < 10 ? '0' : '') + seconds

  // if hours > 0, add 'hours' to the output string
  return (hours > 0 ? hours + ':' : '') + minutes + ':' + seconds
}

function parseTime(seconds) {
  let hours = Math.floor(seconds / 3600)
  let minutes = Math.floor((seconds - hours * 3600) / 60)
  seconds = seconds - hours * 3600 - minutes * 60

  return [hours, minutes, seconds]
}

function timeToMinutes(timeStr) {
  const parts = timeStr.split(':').map(Number);
  
  // Determine the format based on the number of parts
  if (parts.length === 3) {
    const [hours, minutes, seconds] = parts;
    return Math.ceil(hours * 60 + minutes + seconds / 60);
  } else if (parts.length === 2) {
    const [minutes, seconds] = parts;
    return Math.ceil(minutes + seconds / 60);
  } else {
    throw new Error('Invalid time format');
  }
}

export const TimeHelper = {
  formatTime,
  parseTime,
  timeToMinutes
}
