import 'focus-visible'
import '@/styles/tailwind.css'
import '@/styles/markdownPreview.css'
import { AuthProvider } from '../../context/AuthContext'
import { AudiosProvider } from '../../context/AudioContext'
import { AudioProvider } from '../../context/AudioProvider'
import { ContentProvider } from '../../context/ContentContext'
import { ContentWrapper } from '@/components/layouts/ContentWrapper'
import { AnalysisProvider } from 'context/AnalysisContext'
import { NotificationProvider } from '../../context/NotificationContext'
import { ProductProvider } from '../../context/ProductContext'
import { CreditProvider } from '../../context/CreditContext'
import { StorageProvider } from '../../context/StorageContext'
import Notification from '@/components/common/Notification'
import ErrorBoundary from '@/components/common/ErrorBoundary'

export default function App({ Component, ...pageProps }) {
  return (
    <NotificationProvider>
      <ProductProvider>
        <AuthProvider>
          <AudioProvider>
              <CreditProvider>
                <ContentProvider>
                  <StorageProvider>
                    <AudiosProvider>
                      <AnalysisProvider>
                        <ErrorBoundary>
                          <ContentWrapper Component={Component} {...pageProps} />
                        </ErrorBoundary>
                        <Notification />
                      </AnalysisProvider>
                    </AudiosProvider>
                  </StorageProvider>
                </ContentProvider>
              </CreditProvider>
          </AudioProvider>
        </AuthProvider>
      </ProductProvider>
    </NotificationProvider>
  )
}
