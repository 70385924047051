import React, { useState, useEffect } from 'react';
import { PricingPlan } from '@/components/UI/PricingPlan';
import { StripeHelper } from '../../../stripeHelper';
import { useProduct } from '../../../context/ProductContext';
import { useAuth } from '../../../context/AuthContext';
import { StringHelper } from '@/utils/helpers/stringHelper';
import { saveData, getData } from '@/utils/helpers/localStorageHelper';
import { ObjectHelper } from '@/utils/helpers/objectHelper';

export default function Upgrade() {
  const [productData, setProductData] = useState(null);
  const { transformProductData } = useProduct();
  const { currentUser, payment } = useAuth();

  useEffect(() => {
    async function fetchAndCacheProducts() {
      const cachedProducts = getData('products');

      if (!ObjectHelper.isObjectEmpty(cachedProducts)) {
        const transformed = await transformProductData(cachedProducts);

        if (payment && transformed) {
          transformed.tiers = transformed.tiers.filter(tier => tier.name !== StringHelper.capitalize(currentUser?.productPlan));
        }

        setProductData(transformed);
      } else {
        try {
          const activeProducts = await StripeHelper.listActiveProducts();
          const transformed = await transformProductData(activeProducts);

          if (payment && transformed) {
            transformed.tiers = transformed.tiers.filter(tier => tier.name !== StringHelper.capitalize(currentUser?.productPlan));
          }

          setProductData(transformed);
          saveData('products', activeProducts);
        } catch (error) {
          console.error('Failed to fetch or transform products:', error);
        }
      }
    }

    fetchAndCacheProducts();
  }, [transformProductData]); // Correctly specify all used dependencies

  if (!productData) return null; // Explicitly return null when no data is available

  return <PricingPlan pricing={productData} />;
}