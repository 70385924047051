import React, { useState, useEffect, useContext } from 'react'
import { saveData, getData } from '@/utils/helpers/localStorageHelper'


const NotificationContext = React.createContext('')

export function useNotification() {
  return useContext(NotificationContext)
}

export const NotificationProvider = ({ children }) => {
  const [notifications, setNotifications] = useState(() => {
    const savedNotifications = getData('notifications');
    return savedNotifications ? savedNotifications : [];
  });
  const [hoveredNotifications, setHoveredNotifications] = useState(new Set())

  useEffect(() => {
    if (typeof window !== 'undefined') {  // Prevents error during build
      saveData('notifications', notifications);
    }
  }, [notifications]);

function addNotification({
  id,
  message,
  type,
  link,
  canUserDismiss = true,
  fixed = false,
  fixedDuration, // Will show progress bar if fixed and fixedDuration are provided
}) {
  const removeAfterTimeout = (notificationId, duration) => {
    setTimeout(() => {
      if (!hoveredNotifications.has(notificationId)) {
        removeNotification(notificationId);
      }
    }, duration);
  };

  setNotifications((prevNotifications) => {
    // Remove any previous notification with the same id
    const filteredNotifications = prevNotifications.filter(
      (notification) => notification.id !== id
    );

    // Check for duplicates (message, link, type) among the remaining notifications
    const duplicateNotification = filteredNotifications.find(
      (notification) =>
        notification.message === message &&
        notification.link === link &&
        notification.type === type
    );

    if (duplicateNotification) {
      return filteredNotifications;
    }

    // Add the new notification
    const newNotification = { id, message, type, link, canUserDismiss, fixed, fixedDuration };
    return [...filteredNotifications, newNotification];
  });

  if (fixed && fixedDuration && !canUserDismiss) {
    // Only remove after fixedDuration if the user cannot dismiss the notification
    removeAfterTimeout(id, fixedDuration);
  } else if (!fixed && canUserDismiss) {
    // Default duration for dismissible notifications
    removeAfterTimeout(id, 5000);
  }
}


  function removeNotification(id) {
    setNotifications((prevNotifications) =>
      prevNotifications.filter((notification) => notification.id !== id)
    )
  }

  function addHoveredNotification(id) {
    setHoveredNotifications((prevHovered) => {
      const updated = new Set(prevHovered)
      updated.add(id)
      return updated
    })
  }

  function removeHoveredNotification(id) {
    setHoveredNotifications((prevHovered) => {
      const updated = new Set(prevHovered)
      updated.delete(id)
      return updated
    })
  }

  function removeAllNotifications() {
    setNotifications([])
  }

  function updateNotification(id, updatedNotification) {
    setNotifications((prevNotifications) =>
      prevNotifications.map((notification) =>
        notification.id === id ? { ...notification, ...updatedNotification } : notification
      )
    )
  }

  const value = {
    notifications,
    addNotification,
    removeNotification,
    updateNotification,
    addHoveredNotification,
    removeHoveredNotification,
    removeAllNotifications,
  }

  return (
    <NotificationContext.Provider value={value}>
      {children}
    </NotificationContext.Provider>
  )
}
