import React, { useState } from 'react';
import { useNotification } from 'context/NotificationContext';
import { useAuth } from 'context/AuthContext';

export default function Feedback() {
  const { currentUser } =
  useAuth()
  // State to handle form data
  const [formData, setFormData] = useState({
    subject: '',
    message: '',
    firstName: '',
    lastName: '',
    email: ''
  });
  

  // Access the notification context to show success/error messages
  const { addNotification } = useNotification();

  // Handle change in form fields and update state
  const handleChange = (event) => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };

  // Handle form submission
  const handleSubmit = async (event) => {
    event.preventDefault();

    const apiUrl = process.env.NEXT_PUBLIC_FEEDBACK_EMAIL_SENDER; 

    try {
      // Add user information to the formdata
      formData.firstName = currentUser.firstName
      formData.lastName = currentUser.lastName
      formData.email = currentUser.email

      // Sending the feedback data to your backend
      const response = await fetch(apiUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ ...formData, emailTo: 'feedback@openspeech.io' }),
      });

      if (!response.ok) {
        addNotification({
          id: Date.now(),
          message: 'Feedback failed to send',
          type: 'error',
        });
      }

      // Show success notification
      addNotification({
        id: Date.now(),
        message: 'Feedback sent successfully',
        type: 'success',
      });

      // Clear the form fields
      setFormData({
        subject: '',
        message: ''
      });
    } catch (error) {
      // Show error notification
      addNotification({
        id: Date.now(),
        message: 'Feedback failed to send',
        type: 'error',
      });
    }
  };

  return (
    <>
      <h3 className="text-lg font-medium text-gray-800">Send us a message</h3>
      <form
        onSubmit={handleSubmit}
        className="mt-6 grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-8"
      >
        <div className="sm:col-span-2">
          <label
            htmlFor="subject"
            className="block text-sm font-medium text-gray-800"
          >
            Subject
          </label>
          <div className="mt-1">
            <input
              type="text"
              name="subject"
              id="subject"
              value={formData.subject}
              onChange={handleChange}
              className="block w-full rounded-md border-gray-300 py-3 px-4 text-gray-800 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
            />
          </div>
        </div>
        <div className="sm:col-span-2">
          <div className="flex justify-between">
            <label
              htmlFor="message"
              className="block text-sm font-medium text-gray-800"
            >
              Message
            </label>
            <span id="message-max" className="text-sm text-gray-500">
              Max. 500 characters
            </span>
          </div>
          <div className="mt-1">
            <textarea
              id="message"
              name="message"
              rows={4}
              value={formData.message}
              onChange={handleChange}
              className="block w-full rounded-md border-gray-300 py-3 px-4 text-gray-800 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
            />
          </div>
        </div>
        <div className="sm:col-span-2 sm:flex sm:justify-end">
          <button
            type="submit"
            className="mt-2 inline-flex w-full items-center justify-center rounded-md border border-transparent bg-indigo-600 px-6 py-3 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto"
          >
            Submit
          </button>
        </div>
      </form>
    </>
  );
}
